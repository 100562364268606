import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Layout } from "../components";
import helper from "../const/helper";

const PrivateOutlet: React.FC = () => {
  const token = localStorage.getItem(helper.JWT_TOKEN);
  const location = useLocation();
  const tokenEmail = new URLSearchParams(location.search)?.get("token") ?? null;
  if (token) {
    return (
      <Layout>
        <Outlet />
      </Layout>
    );
  } else if (tokenEmail) {
    return <Navigate to="/resetPassword" />;
  } else {
    console.log("Redirecting to login");
    return <Navigate to="/login" />;
  }
};

export default PrivateOutlet;

