import React from "react";
import { FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from "@mui/material";
import styles from "./SelectOption.module.css";
import { ExpandMoreTwoTone } from "@mui/icons-material";

interface ISelectOptions {
  placeholder?: string;
  value?: string;
  label?: string;
  onChange?: (e: SelectChangeEvent) => void;
  options: any[];
}

const SelectOption: React.FC<ISelectOptions> = (props) => {
  const { placeholder, onChange, label, options, value } = props;
  return (
    <FormControl className={styles.formSelect}>
      <Typography variant={"h3"}>{label}</Typography>
      <Select
        className={styles.select}
        displayEmpty
        value={value}
        onChange={onChange}
        IconComponent={ExpandMoreTwoTone}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>{placeholder}</em>;
          }
          return selected;
        }}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectOption;
