import React from "react";
import Form from "../Form";
import { OptionLanguage } from "../../../const/Options";
import { Loader, SelectOption } from "../../index";
import { Box, Typography } from "@mui/material";
import styles from "../Form.module.css";

type ILanguage = {
  language: string
}

type UserFormProps = ILanguage & {
  updateFields: (fields: Partial<ILanguage>) => void;
  loader?: boolean,
  edit?: boolean,
  duration?: number
}
const Language = (props: UserFormProps) => {
  const { language, edit, loader, updateFields } = props;
  return (
    <Form title={!edit ? (loader ? "Transcribing" : "Language") : ""}>
      {!loader ?
        <SelectOption
          options={OptionLanguage}
          value={language}
          label={"Select language the audio file is in"}
          placeholder={"Select language"}
          onChange={e => updateFields({ language: e.target.value })}
        /> :
        <Box className={styles.transcribing}>
          <Loader />
          <Typography variant={"h3"}>
            {/*Hang tight, this might take up to {secondsToMM(duration)} minutes.*/}
            Hang tight, this might take a moment.
          </Typography>
        </Box>
      }

    </Form>
  );
};

export default Language;
