import React from "react";
import styles from "./Loader.module.css";
import clsx from "clsx";

interface ILoader {
  size?: boolean;
}

const Loader = ({ size }: ILoader) => {
  return (
    <div className={clsx(styles.loader, size && styles.loaderSize)}>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
    </div>
  );
};

export default Loader;


