import React, { useEffect, useState } from "react";
import { Box, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from "@mui/material";
import styles from "./HeaderContent.module.css";
import { AccountSetting, CopyIcon, LogoutIcon, ShareIcon } from "../../assets/images/file/files";
import { AccountImage } from "../../assets/images/file";
import { Popup } from "../../components";
import { forceLogout } from "../../services/api/authApi";
import routes from "../../routes/const/routes";
import { useNavigate } from "react-router-dom";
import helper from "../../const/helper";
import clsx from "clsx";
import { getCollections } from "../../services/api/lyricsAPI";

const HeaderContent: React.FC = () => {
  const navigate = useNavigate();
  const [copied, setCopied] = useState<string>("");
  const [dataCollection, setDataCollection] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorShare, setAnchorShare] = useState<HTMLButtonElement | null>(null);
  const user = JSON.parse(localStorage.getItem(helper.USER_DATA) || "{}");
  const open = Boolean(anchorEl);
  const openShare = Boolean(anchorShare);
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorShare(null);
  };

  useEffect(() => {
    const getCollectionsData = async () => {
      const res = await getCollections();
      setDataCollection(res.collections);
    };
    getCollectionsData?.();
  }, [anchorShare]);

  const handleSettings = () => {
    navigate(routes.accountSettings);
    handleClose();
  };

  const handleLogOut = () => {
    forceLogout();
    navigate(routes.login);
  };
  const handleShare = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorShare(event.currentTarget);
  };

  const handleCopy = async (id: string) => {
    const baseUrl = "https://ctlgmusic.com/collection/";
    const link = baseUrl + id;
    setCopied(id);
    await navigator.clipboard.writeText(link);
  };

  return (
    <>
      <Box className={styles.account}>
        <span onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleShare(event)}
              aria-describedby={openShare ? "simple-popover" : undefined}>
          <ShareIcon />
        </span>
        <span onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
              aria-describedby={open ? "simple-popover" : undefined}>
          <AccountImage />
        </span>
      </Box>
      <Popup id="simple-popover" anchorEl={anchorEl} open={open} handleClose={handleClose} margin={50}>
        <Box className={styles.accountPopup}>
          <ListItem className={styles.accountInfo}>
            <ListItemAvatar>
              <AccountImage size={36} />
            </ListItemAvatar>
            <ListItemText primary={user.legalName || ""} secondary={<Typography>{user.email || ""}</Typography>} />
          </ListItem>
          <Box className={styles.accountInfoInner}>
            <Typography variant="h3" onClick={handleSettings}>
              <AccountSetting />
              <span>Account Settings</span>
            </Typography>
            <Typography variant="h3" onClick={handleLogOut}>
              <LogoutIcon />
              <span>Log Out</span>
            </Typography>
          </Box>
        </Box>
      </Popup>
      <Popup id="simple-popover" anchorEl={anchorShare} open={openShare} handleClose={handleClose} margin={80}>
        <Box className={styles.sharePopup}>
          <Typography variant={"h1"}>Share Collection</Typography>
          <div className={styles.sharePopupInner}>
            {dataCollection && dataCollection.map((e: any) => (
              <div key={e.id} onClick={() => handleCopy(e.id)}>
                <Tooltip title={e.name}>
                  <span className={styles.sharePopupInnerName}>{e.name}</span>
                </Tooltip>
                {copied !== e.id ?
                  <span className={clsx(styles.sharePopupCopy)}><CopyIcon /> Copy link</span> :
                  <span className={clsx(styles.sharePopupCopied)}>LINK COPIED</span>
                }
              </div>
            ))}
          </div>
        </Box>
      </Popup>
    </>
  );
};

export default HeaderContent;
