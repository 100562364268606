import moment from "moment/moment";

export const formatFileSize = (bytes: any) => {
  if (bytes < 1024) {
    return bytes + " B";
  } else if (bytes < 1024 * 1024) {
    return Number((bytes / 1024).toFixed(2)) + " kb";
  } else if (bytes < 1024 * 1024 * 1024) {
    return Number((bytes / (1024 * 1024)).toFixed(2)) + " mb";
  } else {
    return Number((bytes / (1024 * 1024 * 1024)).toFixed(2)) + " gb";
  }
};
export const formatDuration = (durationInSeconds: number) => {
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds % 60);

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formattedMinutes}:${formattedSeconds}`;
};


export const secondsToMMSS = (seconds: any) => moment.utc(seconds * 1000).format("mm:ss");
export const secondsToMM = (seconds: any) => moment.utc(seconds * 1000).format("m");


export const convertAudioFileToBase64 = (audioFile: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = () => {
      reject(new Error("Failed to read audio file."));
    };

    reader.onload = () => {
      const result = reader.result;

      if (typeof result === "string") {
        resolve(result);
      } else {
        reject(new Error("Failed to convert audio file to base64."));
      }
    };

    reader.readAsDataURL(audioFile);
  });
};

