import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import styles from "./UpdateSong.module.css";
import { FormData, IGenreInfo, ILyricInfo, IMoodInfo } from "../../modules/UploadFIle/types";
import { CustomButton } from "../index";
import Language from "../Form/FormSteps/Language";
import Attributes from "../Form/FormSteps/Attributes";
import Review from "../Form/FormSteps/Review";
import { postUpload } from "../../services/api/lyricsAPI";
import { enqueueSnackbar } from "notistack";
import { snackbarPosTopRight } from "../Notification/types";
import ArtistInfo from "../Form/FormSteps/ArtistInfo";
import { capitalizeFirstLetter } from "../../utils/common";

interface IUpdateSong {
  dataSong: any;
  setEditSong: (e: boolean) => void;
  fetchData?: () => void;
  setCurrentSong?: any;
}

const UpdateSong: React.FC<IUpdateSong> = (props) => {
  const { dataSong, setCurrentSong, fetchData, setEditSong } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [data, setData] = useState<FormData>({
    file: null,
    duration: dataSong?.duration,
    id: dataSong?.id,
    song_title: dataSong?.title,
    composers: dataSong.artists.map((artist: ILyricInfo) => (artist)),
    genre: dataSong.genre.map((genre: IGenreInfo) => ({ genre: genre })),
    mood: dataSong.mood.map((mood: IMoodInfo) => ({ mood: mood })),
    tempo: dataSong.tempo,
    tags: dataSong.keyLyrics,
    type: dataSong.type,
    campaignCode: dataSong.campaignCode,
    songText: dataSong?.songText,
    language: dataSong.language
  });
  const updateFields = (fields: Partial<FormData>) => {
    setData((prev) => ({ ...prev, ...fields }));
  };
  const handleUpdate = async () => {
    setLoading(true); // Assuming setLoading updates the loading state correctly
    try {
      const updateData = {
        songId: data?.id,
        title: data?.song_title,
        language: data?.language,
        songText: data?.songText,
        artists: data?.composers,
        genre: data?.genre.map((e) => e.genre),
        mood: data?.mood.map((e) => e.mood),
        tempo: data.tempo,
        keyLyrics: data?.tags.map((e) => e),
        campaignCode: data?.campaignCode.map((e) => e)
      };
      const res = await postUpload(updateData);
      if (res.msg) {
        setCurrentSong((prev: any) => ({ ...prev, ...updateData }));
        enqueueSnackbar("Successfully edited", {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
        setEditSong?.(false);
        fetchData?.();
      } else {
        setError(res.response.data.error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const sum = data.composers.reduce((accumulator, item) => accumulator + +item.split, 0);
    sum === 100 ? setError("") : setError(sum !== 0 ? "Spit total must equal 100 to continue." : "");
  }, [data.composers]);
  return (
    <Box className={styles.updateSong}>
      <Typography variant={"h2"}>Edit</Typography>
      <ArtistInfo key={2} {...data} updateFields={updateFields} edit />
      <Attributes key={3} {...data} updateFields={updateFields} edit />
      {data.type !== "Instrumental" && <Language key={4} {...data} updateFields={updateFields} edit />}
      {data.type !== "Instrumental" && <Review key={5} {...data} updateFields={updateFields} edit />}
      {Boolean(error) && <Typography className={styles.error}>{capitalizeFirstLetter(error)}</Typography>}
      <CustomButton disabled={Boolean(error)} color={"black"} loading={loading}
                    onClick={handleUpdate}>Save</CustomButton>
    </Box>
  );
};

export default UpdateSong;
