import React, { useState } from "react";
import styles from "../../modules/Auth/Auth.module.css";
import { FormControl, Typography } from "@mui/material";
import Default from "../../assets/images/LoginImage.png";
import { ResetPasswordConst } from "../../const/Auth";
import { CustomButton, CustomInput } from "../../components";
import { Logo } from "../../assets/images/file";
import { useFormik } from "formik";
import { Back } from "../../assets/images/file/files";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/api/userAPI";
import { setRole, setToken, setUser } from "../../store/reducer";
import routes from "../../routes/const/routes";
import { useDispatch } from "react-redux";
import { getProfile } from "../../services/api/lyricsAPI";
import { RESET_PASSWORD_PATTERN } from "../../const/regex";

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search)?.get("token") ?? null;
  const [error, setError] = useState<string>("");
  const formik = useFormik({
    initialValues: ResetPasswordConst,
    validationSchema: RESET_PASSWORD_PATTERN,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const req = await resetPassword({
        code: token,
        password: values.password
      });
      if (req.jwt) {
        dispatch(setToken(req.jwt));
        dispatch(setRole(req.role));
        const userData = await getProfile();
        dispatch(setUser(userData));
        (req.jwt) && navigate(routes.dashboard);
      } else {
        setError("Your password reset link has expired, please reset your password again");
      }
    }
  });

  return (
    <Typography component="div" className={styles.root}>
      <div><img src={Default} alt="Default" loading="lazy" /></div>
      <div className={styles.container}>
        <Logo handleClick={() => navigate(routes.dashboard)} />
        <Typography mt={26} mb={2} component="div" className={styles.title}>
          <div className={styles.titleArrow} onClick={() => navigate(routes.forgotPassword)}>
            <Back />
          </div>
          Create new password
        </Typography>
        <Typography mb={2} component="div" className={styles.subtitle}>
          Your new password must be different from previous used passwords.
        </Typography>
        <FormControl component="form" onSubmit={formik.handleSubmit}>
          <CustomInput
            value={formik.values.password}
            onChange={formik.handleChange("password")}
            onBlur={formik.handleBlur("password")}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            label="New password"
            type={"password"}
            id={"password"}
            placeholder="New password"
          />
          <CustomInput
            value={formik.values.confirmPassword}
            onChange={formik.handleChange("confirmPassword")}
            onBlur={formik.handleBlur("confirmPassword")}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            label="Confirm New password"
            placeholder="Confirm New password"
            type={"password"}
            id={"new-password"}
          />
          <Typography mt={1} className={styles.error}>{error}</Typography>
          <Typography mt={2}>
            <CustomButton color="black" type="submit">
              Reset Password
            </CustomButton>
          </Typography>
          {Boolean(error) && <CustomButton color="black" onClick={() => navigate(routes.forgotPassword)}>
            Resend link
          </CustomButton>}
        </FormControl>
      </div>
    </Typography>
  );
};

export default ResetPassword;
