import React, { useContext, useEffect, useState } from "react";
import { IconButton, Slider } from "@mui/material";
import { Next, Previous } from "../../assets/images/file/files";
import style from "./Player.module.css";
import { AudioContext } from "../../context/AudioContext";
import { secondsToMMSS } from "../../utils/format";
import { Info, Pause, Volume } from "../../assets/images/file";
import useResize from "../../hooks/useResize";

interface IProps {
  setCurrentSong?: any;
  setOpenDescription?: (e: boolean) => void;
}

const Player: React.FC<IProps> = (props) => {
  const { setCurrentSong, setOpenDescription } = props;
  const { stopTrack, audio, currentTrack, prevTrack, nextTrack, isPlaying } =
  useContext(AudioContext) || { audio: null };
  const innerWidth = useResize();
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [volume, setVolume] = useState<number>(50);
  const sliderCurrentTime = Math.round((currentTime / Math.max(currentTrack?.duration || 1, 1)) * 100);
  const formattedDuration = secondsToMMSS(currentTrack?.duration);
  const formattedCurrentTime = secondsToMMSS(currentTime);
  const handleChangeCurrentTime = (_: Event, value: number | number[]) => {
    if (!Array.isArray(value) && audio) {
      const time = Math.round((value / 100) * Math.max(currentTrack?.duration || 1, 1));
      setCurrentTime(time);
      audio.currentTime = time;
    }
  };

  const handleOffVolume = () => {
    if (audio) {
      const newVolume = volume === 0 ? 50 : 0;
      setVolume(newVolume);
      audio.volume = newVolume / 100;
    }
  };
  const handleChangeVolume = (_: Event, value: number | number[]) => {
    if (!Array.isArray(value) && audio) {
      setVolume(value);
      audio.volume = value / 100;
    }
  };


  useEffect(() => {
    const timeInterval = setInterval(() => {
      setCurrentTime(audio?.currentTime || 0);
      if (formattedDuration === formattedCurrentTime) {
        nextTrack?.();
        setCurrentTime(0);
      }
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [audio, formattedDuration, formattedCurrentTime, nextTrack]);


  return (
    <>
      {currentTrack && (
        <div className={style.playbar}>
          <div className={style.playbarRow}>
            {innerWidth?.innerWidth < 850 && <Info handleClick={() => {
              setOpenDescription?.(true);
              setCurrentSong(currentTrack);
            }} />}
            {innerWidth?.innerWidth > 850 &&
              <div>
                <h4 className={currentTrack.title.length > 40 ? style.playbarTextAuto : ""}>
                  <span>{currentTrack.title}</span>
                </h4>
                <p>{currentTrack.artists.map(e => e.name).join(", ")}</p>
              </div>}
            <div className={style.slider_center}>
              <div>
                <IconButton onClick={prevTrack}>
                  <Previous />
                </IconButton>
                <IconButton className={style.playBtn}>
                  <Pause play={isPlaying} handleClick={() => stopTrack?.()} />
                </IconButton>
                <IconButton onClick={nextTrack}>
                  <Next />
                </IconButton>
              </div>
              <div>
                {formattedCurrentTime}
                <Slider
                  color="info"
                  step={1}
                  min={0}
                  max={100}
                  value={sliderCurrentTime}
                  onChange={handleChangeCurrentTime}
                />
                {formattedDuration}
              </div>
            </div>
            {innerWidth?.innerWidth > 850 &&
              <div className={style.slider}>
                <Volume off={volume !== 0} handleClick={handleOffVolume} />
                <Slider
                  color="info"
                  aria-label="Volume"
                  valueLabelDisplay="auto"
                  step={1}
                  min={0}
                  max={100}
                  value={volume}
                  onChange={handleChangeVolume}
                />
              </div>}
          </div>

        </div>
      )}
    </>
  );
};

export default Player;
