import React, { useCallback, useState } from "react";
import Form from "../Form";
import { Box, Tooltip, Typography } from "@mui/material";
import styles from "../Form.module.css";
import { DragIcon, MusicIcon } from "../../../assets/images/file/files";
import { formatDuration, formatFileSize } from "../../../utils/format";
import { UploadTrackProps } from "./types";


const UploadTrack: React.FC<UploadTrackProps> = ({ file, duration, updateFields }) => {
  const [error, setError] = useState<string>("");
  const handleFileInputChange = (file: File) => {
    const audio = new Audio();
    audio.src = URL.createObjectURL(file);
    audio.onloadedmetadata = () => {
      const durationInSeconds = audio.duration;
      updateFields({ file, duration: durationInSeconds });
    };
  };

  const handleUpload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".mp3, .wav, .flac";
    fileInput.onchange = (event: any) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        setError("");
        const validFormats = [".mp3", ".wav", ".flac"];
        const fileExtension = selectedFile.name.split(".").pop();
        if (validFormats.includes(`.${fileExtension}`)) {
          handleFileInputChange(selectedFile);
        } else {
          // Invalid format, show an error message or take appropriate action
          !file && setError("Incorrect file type. Please try again.");
        }
      }
    };
    fileInput.click();
  };

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const selectedFile = event.dataTransfer.files?.[0];
      selectedFile && handleFileInputChange(selectedFile);
    },
    [handleFileInputChange]
  );
  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  return (
    <Form title={"Upload"}>
      <Box className={styles.uploadFile} onDrop={handleDrop} onDragOver={handleDragOver}>
        {file ? <MusicIcon /> : <DragIcon />}
        <Tooltip title={file && file.name} placement="top">
          <Typography variant={"h4"} className={file ? styles.file : ""}>{file ? file.name :
            <span>Drag & drop file or <u onClick={handleUpload}>Browse</u></span>}</Typography>
        </Tooltip>

        {file && (
          <>
            <Box className={styles.fileInfo}>
              <Typography variant={"h4"}>
                File size:
                <span>{formatFileSize(file.size)}</span>
              </Typography>
              <Typography variant={"h4"}>
                Duration:
                <span>{formatDuration(duration)}</span>
              </Typography>
            </Box>
            <Typography mt={5} onClick={handleUpload}>
              <u>Re-upload song</u>
            </Typography>
          </>
        )}
        {!file && (
          <Typography className={styles.fileFormat} mb={5}>
            Supported: MP3, WAV, FLAC
          </Typography>
        )}
        {error && <Typography className={styles.error}>
          {error}
        </Typography>}
      </Box>
    </Form>
  );
};
export default UploadTrack;
