import React, { useEffect } from "react";
import { waveform } from "ldrs";

const Waveform = () => {
  useEffect(() => waveform.register(), []);

  return <l-waveform size="15" stroke="1.3" speed="1.5" color="#0F0F0F" />;
};

export default Waveform;
