import React, { Fragment, useState } from "react";
import styles from "./TrackDescription.module.css";
import { Box, ButtonGroup, List, ListItem, ListItemText, Tooltip, Typography } from "@mui/material";
import { CustomButton, CustomModal } from "../../components";
import helper from "../../const/helper";
import { MusicIcon } from "../../assets/images/file/files";
import { postInquire } from "../../services/api/lyricsAPI";
import { formatDuration } from "../../utils/format";
import { useParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import useResize from "../../hooks/useResize";


interface ITrackDesc {
  setEditSong?: (e: boolean) => void;
  currentSong: any;
  setOpenDescription?: (e: boolean) => void;
}

interface IRender {
  label: string,
  data: string[] | string,
  key?: string | any,
  keys?: string | any,

}


const TrackDescription: React.FC<ITrackDesc> = (props) => {
  const role = localStorage.getItem(helper.ROLE); // Assuming localStorage.getItem(helper.ROLE) returns "Composer/Songwriter"
  const params = useParams();
  const innerWidth = useResize();
  const { setEditSong, setOpenDescription, currentSong } = props;
  const [download, setDownload] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const isMultipleArtists = currentSong?.artists.length !== 1;
  const composersLabel = isMultipleArtists ? "Composers" : "Composer";
  const composersKeys = (isMultipleArtists && role !== "Content creator" && !params.id) ? "split" : undefined;
  const listItemsData: IRender[] = [
    { label: composersLabel, data: currentSong.artists, key: "name", keys: composersKeys },
    { label: "Publishers", data: currentSong.artists, key: "publisher" },
    { label: "Genre", data: currentSong.genre },
    { label: "Mood", data: currentSong.mood },
    { label: "Tempo (BPM)", data: currentSong.tempo },
    { label: "Key Lyrics", data: currentSong.keyLyrics }
  ];
  const renderListItems = ({ label, data, key, keys }: IRender) => {
    const formatData = (e: any) => {
      if (keys === 100) {
        return e[key];
      } else {
        return key && keys ? `${e[key]} (${e[keys]})` : key ? e[key] : e;
      }
    };
    const displayData = (Array.isArray(data) ? data : [data])
      .map(formatData)
      .filter(item => item && item !== "") // Filter out empty items
      .join(", ");

    return displayData && (
      <ListItem divider key={label}>
        <ListItemText primary={label} />
        <Tooltip title={displayData}>
          <Typography align="right" variant="h3">
            {displayData}
          </Typography>
        </Tooltip>
      </ListItem>
    );
  };
  const handleInquire = async (id: number) => {
    try {
      const res = await postInquire({ songId: id });
      if (res.msg) setSent(true);
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleDownloadFile = async (fileContent: string, fileName: string) => {
    try {
      const response = await fetch(fileContent);
      const blob = await response.blob();
      // Create a link element
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = fileName;
      // Append to the document and trigger download
      document.body.appendChild(link);
      link.click();
      setDownload(false);
      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };
  const handleClose = () => {
    setModal(false);
    setSent(false);
  };

  const renderedListItems = listItemsData.map((item, index) => (
    <Fragment key={index}>
      {renderListItems(item)}
    </Fragment>
  ));
  return (
    <div className={styles.box}>
      {innerWidth?.innerWidth < 850 &&
        <div onClick={() => setOpenDescription?.(false)} className={styles.boxClose}><Close /></div>}
      <Box className={styles.boxHead}>
        <Typography mt={1} mb={1} className={styles.boxTitle}>{currentSong.title}</Typography>
        <Typography mb={2}>
          {currentSong?.artists?.map((e: { name: string }) => e.name).join(", ")}
        </Typography>
        {!params.id &&
          <ButtonGroup>
            {(role === "Composer/Songwriter" || role === "admin") &&
              <CustomButton
                loading={download}
                animation
                color="white"
                size="small"
                colorText="black"
                onClick={() => {
                  handleDownloadFile?.(currentSong.filePath, currentSong.fileName);
                  setDownload(true);
                }}>
                Download
              </CustomButton>
            }
            {(role === "Content creator") &&
              <CustomButton
                animation
                color="white"
                size="small"
                colorText="black"
                onClick={() => setModal(true)}>
                Inquire
              </CustomButton>
            }
            {role === "Composer/Songwriter" &&
              <CustomButton
                animation
                color="black"
                size="small"
                colorText="white"
                onClick={() => setEditSong?.(true)}>
                Edit
              </CustomButton>}
          </ButtonGroup>}
      </Box>
      <List className={styles.boxContent}>
        {renderedListItems}
        {currentSong?.type !== "Instrumental" &&
          <>
            <ListItem divider>
              <ListItemText primary="Language" />
              <Typography align="right" variant="h3">{currentSong.language}</Typography>
            </ListItem>
            <Typography align="left" mt={3} mb={3}>Lyrics</Typography>
            <textarea
              id={currentSong.songText}
              readOnly
              className={styles.boxTextarea}
              value={currentSong.songText}
            /></>
        }
      </List>
      <CustomModal isOpen={modal} onClose={() => handleClose()}>
        <div className={styles.modal}>
          {!sent ?
            <>
              <span className={styles.title}>Inquire</span>
              <span className={styles.subtitle}>Start a dialog with CTLG about licensing this track:</span>
              <div className={styles.info}>
                <MusicIcon />
                <span>{currentSong?.artists?.map((e: {
                  name: string
                }) => e.name).join(", ")} - {currentSong.title}</span>
                <span>Duration: {formatDuration(currentSong?.duration)}</span>
              </div>
              <div className={styles.btn}>
                <CustomButton endIcon
                              color="black"
                              size="small"
                              onClick={() => handleInquire(currentSong?.id)}
                              colorText="white">
                  SEND INQUIRY
                </CustomButton>
              </div>
            </> :
            <div className={styles.modalSent}>
              <span className={styles.title}>Inquiry sent!</span>
              <span>
            A CTLG representative will be in touch shortly. <br />
            Thank you.
          </span>
            </div>
          }
        </div>

      </CustomModal>
    </div>
  );
};
export default TrackDescription;
