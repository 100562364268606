import {
  DataArtist,
  DataCampaignCode,
  DataGenre,
  DataMood,
  DataTags,
  FormData,
  ILyricInfo
} from "../../../modules/UploadFIle/types";

export const INITIAL_DATA: FormData = {
  file: null,
  duration: 0,
  id: 0,
  song_title: "",
  composers: DataArtist,
  genre: DataGenre,
  mood: DataMood,
  tempo: "",
  type: "",
  tags: DataTags,
  campaignCode: DataCampaignCode,
  language: ""
};

export interface UploadTrackProps {
  file: File | null;
  duration: number;
  updateFields: (fields: { duration: number; file: File | null }) => void;
}


export interface IArtist {
  song_title: string;
  edit?: boolean;
  composers: ILyricInfo[];
  artistDict?: { [key: number]: { artist_name: string } };
  artists?: string[];
  sum?: number;
  error?: string;
}
