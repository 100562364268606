import React, { ReactNode } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "../main.css";
import routes from "./const/routes";
import { Header } from "../components";
import PrivateOutlet from "../pages/PrivateOutlet";
import helper from "../const/helper";
import { AccountSettings, Dashboard, ForgotPassword, ResetPassword } from "../pages";
import { Login, Registration } from "../modules";
import CollectionPage from "../pages/CollectionPage/CollectionPage";


interface WrapperAppProps {
  children: ReactNode;
}

export const WrapperApp: React.FC<WrapperAppProps> = ({ children }) => {
  const token = localStorage.getItem(helper.JWT_TOKEN);
  return (
    token ?
      <div className="wrapper_app">
        <div className="wrapper_main">
          <Header />
          {children}
        </div>
      </div>
      : <Navigate to={"/"} />
  );
};


const MainRouter: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path={routes.login} element={<Login />} />
      <Route path={routes.registration} element={<Registration />} />
      <Route path={routes.forgotPassword} element={<ForgotPassword />} />
      <Route path={routes.resetPassword} element={<ResetPassword />} />
      <Route path={routes.collection} element={<CollectionPage />} />
      <Route path={routes.home} element={<PrivateOutlet />}>
        <Route path={routes.dashboard} element={<WrapperApp><Dashboard /></WrapperApp>} />
        <Route path={routes.accountSettings} element={<WrapperApp><AccountSettings /></WrapperApp>} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default MainRouter;


