import React, { useEffect, useState } from "react";
import { Box, ButtonGroup, Tab, Tabs } from "@mui/material";
import styles from "./Tabs.module.css";
import { getCollections, postCollection } from "../../services/api/lyricsAPI";
import { CustomButton, CustomInput, CustomModal } from "../index";
import { enqueueSnackbar } from "notistack";
import { snackbarPosTopRight } from "../Notification/types";
import clsx from "clsx";
import { Plus } from "../../assets/images/file";

export interface ICollection {
  id: string | null;
  name: string;
}

export interface ModalState {
  value: string;
  open: boolean;
}

interface IProps {
  collection: any;
  update?: boolean;
  setCollection: any;
  setDataCollect: any;
  loading: boolean;
}

const TabsBlock: React.FC<IProps> = (props) => {
  const { setCollection, update, setDataCollect, loading, collection } = props;
  const [loader, setLoader] = useState<boolean>(true);
  const [collections, setCollections] = useState<ICollection[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>("All");
  const [modalData, setModalData] = useState<ModalState>({ value: "", open: false });
  useEffect(() => {
    if (collection && collection.name === "") {
      setSelectedTab("All");
    }
  }, [collection && collection.name]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionsData = await getCollections();
        setCollections(collectionsData.collections);
        setDataCollect(collectionsData.collections);
        setLoader(false);
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };
    fetchData?.();
  }, [loading, update]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    event.preventDefault();
    setSelectedTab(newValue);
    const filtered = collections.find((e: any) => e.id === newValue);
    setCollection(filtered);
  };

  const handleCreateCollection = async () => {
    try {
      const res = await postCollection({ collectionName: modalData.value });
      if (res) {
        setModalData({ ...modalData, open: false, value: "" });
        enqueueSnackbar(`Collection '${modalData.value}' successfully created`, {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
        const updatedCollections = await getCollections();
        setCollections(updatedCollections.collections);
      }
    } catch (error) {
      console.error("Error creating collection:", error);
    }
  };

  return (
    <div className={styles.row}>
      {!loader &&
        <div className={clsx(styles.root, !loader && styles.rootAnimate)}>
          <Tabs
            className={styles.tabs}
            value={selectedTab}
            onChange={handleChange}
            variant="scrollable"
            aria-label="scrollable prevent tabs example"
            scrollButtons={false}
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            <Tab label="All Music" className={styles.tab} value="All" />
            {collections && collections.filter(collection => collection.name !== "All Music").map((collection, index) => (
              <Tab key={collection.id} value={collection.id}
                   label={<span className={styles.customTab}>{collection.name}</span>}
                   className={styles.tab}
                   data-index={index} />
            ))}
          </Tabs>
          <div className={styles.collectionAddButton} onClick={() => setModalData({ ...modalData, open: true })}>
            <Plus stroke={"#000"} />
            Collection
          </div>
          <CustomModal isOpen={modalData.open} deleteModal>
            <Box className={styles.tagsModal}>
              <CustomInput
                label="New collection name"
                placeholder="Collection name"
                value={modalData.value}
                onChange={(e) => setModalData({ ...modalData, value: e.target.value })}
              />
              <ButtonGroup>
                <CustomButton
                  color="#E9E9E9"
                  colorText="black"
                  borderColor="#E9E9E9"
                  animation
                  onClick={() => setModalData({ ...modalData, open: false, value: "" })}
                >
                  Cancel
                </CustomButton>
                <CustomButton color="black" onClick={handleCreateCollection}>
                  Save
                </CustomButton>
              </ButtonGroup>
            </Box>
          </CustomModal>
        </div>}
    </div>
  );
};

export default TabsBlock;
