import React from "react";

interface Props {
  handleClick?: () => void;
}

export const Info: React.FC<Props> = ({ handleClick }) => {
  return (
    <svg onClick={handleClick} width="34" height="34" viewBox="0 0 34 34" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <circle cx="17" cy="17" r="16" stroke="white" strokeWidth="2" />
      <rect x="16" y="15" width="2" height="10" fill="white" />
      <rect x="16" y="8" width="2" height="3" fill="white" />
    </svg>

  );
};




