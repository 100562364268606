import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducer";

const combine = combineReducers({
  user: authReducer
});

const store = configureStore({ reducer: combine });

export { store };
