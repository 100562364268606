import React, { ReactNode, useEffect, useState } from "react";
import styles from "./Layout.module.css";
import { useNavigate } from "react-router-dom";
import { AdaptiveScreen } from "../../modules";
import routes from "../../routes/const/routes";

export interface LayoutProps {
  children: ReactNode;
}

const Layout = React.memo(({ children }: LayoutProps) => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  useEffect(() => {
    location.pathname === "/" && navigate(routes.dashboard);
  }, []);


  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={styles.root}>
      {windowWidth > 960 ? (
        <div className={styles.root}>
          <main className={styles.main}>
            {children}
          </main>
        </div>
      ) : (
        <AdaptiveScreen />
      )}
    </div>
  );
});

export default Layout;
