export const OptionGenre = [
  "Classical",
  "Country",
  "Electronic/Techno",
  "Hip-hop/Rap",
  "Indie Rock",
  "Industrial",
  "Jazz",
  "Metal",
  "New Wave",
  "Oldies",
  "Pop",
  "Punk",
  "R&B",
  "Rock"
];
export const OptionType = [
  "Instrumental",
  "Vocal"
];
export const OptionMood = [
  "Angry",
  "Dark",
  "Dreamy",
  "Eccentric",
  "Epic",
  "Euphoric",
  "Funny",
  "Glamorous",
  "Happy",
  "Hopeful",
  "Laid Back",
  "Marching",
  "Peaceful",
  "Quirky",
  "Relaxing",
  "Romantic",
  "Sad",
  "Sexy",
  "Smooth"
];
export const OptionTempo = [
  "1–40",
  "41–60",
  "61–80",
  "81–100",
  "101–120",
  "121–140",
  "141–160",
  "160+"
];
export const OptionLanguage = [
  "English",
  "Italian",
  "Spanish",
  "French",
  "German"
];

