import React, { useState } from "react";
import styles from "../../modules/Auth/Auth.module.css";
import { FormControl, Typography } from "@mui/material";
import Default from "../../assets/images/LoginImage.png";
import { ForgotPasswordConst } from "../../const/Auth";
import { CustomButton, CustomInput } from "../../components";
import { Logo } from "../../assets/images/file";
import { useFormik } from "formik";
import { forgotPassword } from "../../services/api/userAPI";
import * as Yup from "yup";
import { Massage } from "../../assets/images/file/files";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/const/routes";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(ForgotPasswordConst);
  const [error, setError] = useState<string>("");
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required")
  });

  const formik = useFormik({
    initialValues: ForgotPasswordConst,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const email = {
        email: values.email
      };
      const res = await forgotPassword(email);
      if (res.msg === "Success") {
        setData((prevState) => ({ ...prevState, sent: true }));
      } else {
        setError("Email is not required");
      }

    }
  });

  return (
    <Typography component="div" className={styles.root}>
      <div><img src={Default} alt="Default" loading="lazy" /></div>
      {!data.sent ?
        <div className={styles.container}>
          <Logo handleClick={() => navigate(routes.login)} />
          <Typography mt={26} mb={2} component="div" className={styles.title}>
            Reset Password
          </Typography>
          <Typography mb={2} component="div" className={styles.subtitle}>
            Enter the email associated with your account, and we’ll send an email with instructions to reset your
            password.
          </Typography>
          <FormControl component="form" onSubmit={formik.handleSubmit}>
            <CustomInput
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur("email")}
              error={formik.touched.email && Boolean(formik.errors.email) || error}
              helperText={formik.touched.email && formik.errors.email || error}
              label="Email"
              id="email"
              placeholder="example@email.com"
            />
            <Typography mt={4}>
              <CustomButton color="black" type="submit">
                Send Instructions
              </CustomButton>
            </Typography>
          </FormControl>
        </div>
        :
        <div className={styles.container}>
          <Logo />
          <Typography mt={26} mb={2} component="div" className={styles.title}>
            <Massage />
            <div>
              Check your email
            </div>
          </Typography>
          <Typography mb={2} component="div" className={styles.subtitle}>
            We have sent password reset instructions to your email.
          </Typography>
          <Typography mt={4} mb={2} component="div" className={styles.subtitle}>
            Did not receive the email? Check your spam folder, or{" "}
            <u onClick={() => setData((prevState) => ({ ...prevState, sent: false }))}>
              try another email address
            </u>.
          </Typography>
        </div>

      }
    </Typography>
  );
};

export default ForgotPassword;
