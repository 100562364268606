import React from "react";
import Form from "../Form";
import { Box, Typography } from "@mui/material";
import styles from "../Form.module.css";
import { IAttributes } from "../../../modules/UploadFIle/types";
import { OptionGenre, OptionMood, OptionType } from "../../../const/Options";
import { CustomInput, SelectOption, TagInput } from "../../index";

type UserFormProps = IAttributes & {
  updateFields: (fields: Partial<IAttributes>) => void;
  [key: string]: any;
  type?: string
  tempo?: string
};

const Attributes = (props: UserFormProps) => {
  const { updateFields, edit, tempo, type, tags, campaignCode } = props;
  const handleAdd = (key: string) => {
    const newArray = { [key]: "" };
    updateFields({ [key]: [...props[key], newArray] });
  };

  const handleRemove = (key: string, indexToRemove: number) => {
    const updatedItems = props[key].filter((_: any, index: number) => index !== indexToRemove);
    updateFields({ [key]: updatedItems });
  };
  const renderSelectOptions = (items: any[], key: string, options: any[], placeholder: string) =>
    items.map((e, index) => (
      <>
        <SelectOption
          key={index}
          value={e[key]}
          options={options}
          placeholder={placeholder}
          onChange={(event) => {
            const updatedItems = [...items];
            updatedItems[index] = { ...updatedItems[index], [key]: event.target.value };
            updateFields({ [key]: updatedItems });
          }}
        />
        {items?.length > 1 &&
          <Typography className={styles.formHandleRemove} onClick={() => handleRemove(key, index)}>
            Remove
          </Typography>}
      </>
    ));

  const renderFormSection = (sectionKey: string, sectionLabel: string, sectionOptions: any[]) => (
    <>
      <Box className={styles.formGenre}>
        <Typography variant={"h4"}>{sectionLabel}</Typography>
        {renderSelectOptions(props[sectionKey], sectionKey, sectionOptions, `Select ${sectionLabel}`)}
      </Box>
      {(sectionKey !== "type") &&
        <Typography className={styles.formHandleAnother} onClick={() => handleAdd(sectionKey)}>
          + Another
        </Typography>}
    </>
  );
  return (
    <Form title={!edit ? "Attributes" : ""}>
      {!edit && <SelectOption
        options={OptionType}
        value={type}
        label={"Music Type"}
        placeholder={"Select music type"}
        onChange={e => updateFields({ type: e.target.value })}
      />}
      {renderFormSection("genre", "Genre", OptionGenre)}
      {renderFormSection("mood", "Mood", OptionMood)}
      {/*{renderFormSection("tempo", "Tempo (BPM)", OptionTempo)}*/}
      <CustomInput
        value={tempo === 0 ? "" : tempo} // Check if tempo is 0, if so set value to empty string
        onChange={e => {
          let newValue = parseInt(e.target.value);
          newValue = isNaN(newValue) ? 0 : Math.min(Math.max(0, newValue), 10000);
          updateFields({ tempo: newValue });
        }}
        label="Tempo (BPM)"
        placeholder="Tempo (BPM)"
        type={"number"}
        id={"tempo_bpm"}
      />
      <Box mt={1}>
        <TagInput disabled={type === "Instrumental"} name={"tags"} title={"KEY LYRICS (OPTIONAL)"}
                  subtitle={"Add up to three key lyrics"}
                  tags={tags}
                  updateFields={updateFields} />
        <TagInput name={"campaignCode"} title={"Campaign Code (OPTIONAL)"} tags={campaignCode}
                  updateFields={updateFields} />
      </Box>
    </Form>

  );
};

export default Attributes;
