import React, { useEffect } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import Form from "../Form";
import { CustomInput } from "../../index";
import styles from "../Form.module.css";
import { IArtist } from "./types";
import { Info } from "../../../assets/images/file/files";

type UserFormProps = IArtist & {
  updateFields: (fields: Partial<IArtist>) => void;
};

const ArtistInfo = (props: UserFormProps) => {
  const { song_title, sum, edit, error, composers, updateFields } = props;
  const ArtistData = { name: "", publisher: "", split: 0 };

  useEffect(() => {
    (!edit && sum === 0) && updateFields({ composers: [ArtistData] });
  }, [edit]);

  const handleAddArtist = () => {
    updateFields({
      composers: [...composers, ArtistData]
    });
  };
  const handleRemove = (indexToRemove: number) => {
    const updatedArtistNames = composers.filter((_, index) => index !== indexToRemove);
    updateFields({ composers: updatedArtistNames });
  };

  return (
    <Form title={!edit ? "Title and Artist" : ""}>
      <CustomInput
        value={song_title}
        onChange={(e) => updateFields({ song_title: e.target.value })}
        label="Song title"
        id="song_title"
        placeholder={"Song title"}
      />
      <Box className={styles.formArtist}>
        <Typography variant={"h4"}>Composers</Typography>
        {composers.map((e, index) => (
          <div key={index}>
            <Box className={styles.formArtistBlock}>
              <CustomInput
                id={`name_${index}`}
                value={e.name}
                placeholder={"Composer"}
                onChange={(event) => {
                  const updatedArtistName = [...composers];
                  updatedArtistName[index].name = event.target.value;
                  updateFields({
                    composers: updatedArtistName
                  });
                }}
              />
              <CustomInput
                id={`publisher_${index}`}
                value={e.publisher}
                placeholder={"Publisher"}
                onChange={(event) => {
                  const updatedArtistName = [...composers];
                  updatedArtistName[index].publisher = event.target.value;
                  updateFields({ composers: updatedArtistName });
                }}
              />
              <CustomInput
                id={`split_${index}`}
                value={e.split === 0 ? "" : e.split.toString()}
                type={"number"}
                placeholder={"Split"}
                endIcon={
                  <Tooltip
                    arrow placement={"top-end"} title={
                    <Typography className={styles.infoTooltip}>
                      Spit total must equal 100
                    </Typography>
                  }>
                    <Typography color={"inherit"} className={styles.info}><Info /></Typography>
                  </Tooltip>
                }
                onChange={(event) => {
                  const updatedArtistName = [...composers];
                  const inputValue = event.target.value;
                  updatedArtistName[index].split = inputValue === "" ? 0 : Number(inputValue);
                  updateFields({ composers: updatedArtistName });
                }}
              />
            </Box>
            {composers.length > 1 && (
              <Typography variant={"h3"} className={styles.formHandleRemove} onClick={() => handleRemove(index)}>
                Remove
              </Typography>
            )}
          </div>
        ))}
      </Box>
      <Typography variant={"h3"} className={styles.formHandleAnother} onClick={handleAddArtist}>
        + Another
      </Typography>
      {error && <Typography mt={3} className={styles.error}>{error}</Typography>}


    </Form>
  );
};

export default ArtistInfo;

