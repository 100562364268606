import React, { useContext, useEffect, useState } from "react";
import { Box, Container, InputAdornment, Typography } from "@mui/material";
import { Close, SearchIcon, Stars } from "../../assets/images/file/files";
import styles from "./Dashboard.module.css";
import AudioProvider, { AudioContext } from "../../context/AudioContext";
import {
  CustomButton,
  CustomInput,
  CustomModal,
  CustomTable,
  Filter,
  Player,
  Popup,
  UpdateSong
} from "../../components";
import { TrackDescription, UploadFile } from "../../modules";
import { IDataTable } from "../types";
import { getSongs } from "../../services/api/lyricsAPI";
import { PlusIcon } from "../../assets/images/file/PlusIcon";
import helper from "../../const/helper";
import Tabs, { ICollection } from "../../components/Tabs/Tabs";


export interface IDataFilters {
  search?: string;
  mood: string[];
  genre: string[];
  tempo: string[];

}

const initialFiltersData: IDataFilters = {
  search: "",
  mood: [],
  genre: [],
  tempo: []
};


const Dashboard: React.FC = () => {
  const { stopTrack } = useContext(AudioContext) || {};
  const [update, setUpdate] = useState<boolean>(false);
  const [dataCollect, setDataCollect] = useState<any | null>(null);
  const role = localStorage.getItem(helper.ROLE); // Assuming localStorage.getItem(helper.ROLE) returns "Composer/Songwriter"
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editSong, setEditSong] = useState<boolean>(false);
  const [currentSong, setCurrentSong] = useState<any>(null);
  const [collection, setCollection] = useState<ICollection>({ id: null, name: "" });
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<IDataTable | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [anchorCp, setAnchorCp] = React.useState<HTMLButtonElement | null>(null);
  const [dataFilters, setDataFilters] = useState<IDataFilters>(initialFiltersData);
  const open = Boolean(anchorEl);
  const openCampaign = Boolean(anchorCp);
  const filter = open ? "simple-popover" : undefined;
  const campaign = open ? "simple-campaign" : undefined;
  const fetchData = async () => {
    setLoading(true);
    try {
      const songsData = await getSongs({
        collectionId: collection?.id || "",
        search: dataFilters.search || "",
        genre: dataFilters.genre.map(e => (e === "R&B" ? "R%20B" : e)).join(","),
        mood: dataFilters.mood.join(","),
        tempo: dataFilters.tempo.map(e => (e === "160+" ? "160%2B" : e)).join(",")
      });
      setData(songsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching songs:", error);
    }
  };
  useEffect(() => {
    return () => {
      const audioContext = document.getElementById("audio-context");
      audioContext && stopTrack?.();
    };
  }, []);

  useEffect(() => {
    !open && fetchData?.();
  }, [dataFilters, collection]);

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorCp(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleOpen = () => window.open("https://campaigns.ctlgmusic.com", "_blank");
  const handleClickCampaign = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorCp(event.currentTarget);
  const handleClearSearch = () => setDataFilters((prevFilters) => ({ ...prevFilters, search: "" }));

  const handleClear = () => {
    const clearedFilters: IDataFilters = { mood: [], genre: [], tempo: [] };
    setDataFilters((prevFilters) => ({ ...prevFilters, ...clearedFilters, search: "" }));
    handleClose();
  };
  return (
    <Container>
      <AudioProvider>
        <Tabs collection={collection} update={update} setCollection={setCollection} loading={loading}
              setDataCollect={setDataCollect} />
        <Box className={styles.rootContainer}>
          {openDescription && <TrackDescription setEditSong={setEditSong} currentSong={currentSong} />}
          <Box className={styles.container}>
            <Box className={styles.root}>
              <CustomInput
                id="searchData"
                endIcon={dataFilters.search && <Close />}
                type={"text"}
                handleClear={handleClearSearch}
                value={dataFilters.search}
                onChange={(e) => setDataFilters((prevFilters) => ({ ...prevFilters, search: e.target.value }))}
                startIcon={(
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )}
                placeholder={"Search keywords, song titles, artist names, lyrics, etc."}
              />
              <CustomButton filterIcon color={"transparent"}
                            onClick={handleClick}
                            animation={open}
                            colorText={"black"}>Filters</CustomButton>
              <Popup id={filter} anchorEl={anchorEl} open={open} handleClose={handleClose}>
                <Filter dataFilters={dataFilters} handleClick={handleClear} setDataFilters={setDataFilters}
                        fetchData={fetchData}
                        handleClose={handleClose} />
              </Popup>
              {role === "Content creator" ?
                <>
                  <PlusIcon className={styles.plusBtn} handleClick={handleClickCampaign} />
                  <Popup id={campaign} open={openCampaign} anchorEl={anchorCp} handleClose={handleClose}>
                    <Box className={styles.campaignBlock}>
                      <Typography variant={"h3"} onClick={handleOpen}>
                        <Stars />
                        Create campaign
                      </Typography>
                      {/*<Typography variant={"h3"}>*/}
                      {/*  <VideoIcon />*/}
                      {/*  Create video*/}
                      {/*</Typography>*/}
                    </Box>
                  </Popup>
                </> :
                <CustomButton
                  onClick={() => setIsOpen(!isOpen)}
                  startIcon={data?.count === 0 && collection?.name === ""}
                  color={"black"}
                  colorText={"white"}>
                  {data?.count !== 0 ? "Upload files" : (collection?.name === "" ? "Add song" : "Upload files")}
                </CustomButton>}
            </Box>
            <CustomTable
              setUpdate={setUpdate}
              dataCollect={dataCollect}
              loading={loading}
              setCollection={setCollection}
              collection={collection}
              songs={data && data.songs}
              openFilters={open}
              filters={dataFilters}
              count={data && data.count}
              setOpenDescription={setOpenDescription}
              isOpen={setIsOpen}
              fetchData={fetchData}
              setEditSong={setEditSong}
              setCurrentSong={setCurrentSong} />
            <Player />
          </Box>
        </Box>
      </AudioProvider>
      <CustomModal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
        <UploadFile isOpen={isOpen} setIsOpen={setIsOpen} fetchData={fetchData} />
      </CustomModal>
      <CustomModal isOpen={editSong} onClose={() => setEditSong(!editSong)}>
        <UpdateSong dataSong={currentSong} setCurrentSong={setCurrentSong} setEditSong={setEditSong}
                    fetchData={fetchData} />
      </CustomModal>
    </Container>
  );
};

export default Dashboard;
