import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { CancelIcon } from "../../assets/images/file/files";
import styles from "./TagInput.module.css";

interface ITags {
  data: any,
  handleDelete: (data: any) => void
}

const Tags: React.FC<ITags> = (props) => {
  const { data, handleDelete } = props;
  return (
    <Box className={styles.tag}>
      <Stack direction="row" gap={1}>
        <Typography>{data}</Typography>
        <Typography component={"span"} onClick={() => handleDelete(data)}><CancelIcon /></Typography>
      </Stack>
    </Box>
  );
};

export default Tags;
