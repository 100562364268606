import React from "react";

interface Props {
  stroke?: string;
}

export const BackArrow: React.FC<Props> = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="21.5" transform="rotate(-180 22 22)" fill="#FAFAFA" stroke="#0F0F0F" />
      <mask id="mask0_47_1570" maskUnits="userSpaceOnUse" x="10" y="10" width="24" height="24">
        <rect x="34" y="34" width="24" height="24" transform="rotate(-180 34 34)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_47_1570)">
        <path
          d="M22 14.7746L22.725 15.4746L16.7 21.4996L29.225 21.4996L29.225 22.4996L16.7 22.4996L22.725 28.5246L22 29.2246L14.775 21.9996L22 14.7746Z"
          fill="#0F0F0F" />
      </g>
    </svg>

  );
};




