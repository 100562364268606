import React from "react";
import Form from "../Form";
import styles from "../Form.module.css";
import FormPlayer from "../FormPlayer";
import { Typography } from "@mui/material";

interface IReview {
  songText: string,
}

export type TrackData = {
  file: File | null,
  isOpen?: boolean,
  lyric?: string,
  type?: string,
  song_title?: string,
  duration?: number
  edit?: boolean,
  songText?: string,
  updateFields?: (fields: IReview) => void;
}

const Review = (props: TrackData) => {
  const { file, type, edit, lyric, song_title, isOpen, duration, updateFields, songText } = props;
  return (
    <Form title={!edit ? (type === "Vocal" ? "Review Lyrics" : "Finish Upload") : ""}>
      <div className={styles.box}>
        {!edit ? <FormPlayer isOpen={isOpen} lyric={lyric} file={file} duration={duration} song_title={song_title} /> :
          <Typography variant={"h2"}>LYRICS</Typography>
        }
        {type === "Vocal" &&
          <textarea
            onChange={(e) => updateFields && updateFields({ songText: e.target.value })}
            className={styles.boxTextarea} defaultValue={songText} />}
      </div>
    </Form>
  );
};

export default Review;
