import React, { ReactNode } from "react";
import { Popover } from "@mui/material";

interface IPopup {
  id: any;
  open: boolean;
  anchorEl: any;
  margin?: number;
  vertical?: "bottom" | "center" | "top";
  horizontal?: "left" | "center" | "right";
  handleClose: () => void;
  children: ReactNode;
}

const Popup: React.FC<IPopup> = (props) => {
  const {
    id,
    anchorEl,
    open,
    handleClose,
    margin,
    vertical = "bottom",
    horizontal = "left",
    children
  } = props;
  return (
    <>
      {anchorEl && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          marginThreshold={margin}
          onClose={handleClose}
          anchorOrigin={{
            vertical: vertical,
            horizontal: horizontal
          }}
        >
          {children}
        </Popover>
      )}
    </>
  );
};

export default Popup;
