export interface IAttributes {
  genre: IGenreInfo[],
  mood: IMoodInfo[],
  tempo?: string | any,
  type?: string,
  tags: ITagsData[]
  campaignCode: ICampaignCode[]
}


export interface ILyricInfo {
  name: string,
  publisher: string,
  split: number
}

export interface IMoodInfo {
  mood: string,
}

export interface ITagsData {
  tags: string[],
}

export interface ICampaignCode {
  campaignCode: string,
}

export interface IGenreInfo {
  genre: string,
}

export const DataArtist: ILyricInfo[] = [{
  name: "",
  publisher: "",
  split: 0
}];

export const DataGenre: IGenreInfo[] = [{ genre: "" }];
export const DataMood: IMoodInfo[] = [{ mood: "" }];
// export const DataTempo: ITempoInfo[] = [{ tempo: "" }];
export const DataTags: ITagsData[] = [];
export const DataCampaignCode: ICampaignCode[] = [];

export interface FormData {
  file: File | null;
  lyric?: string,
  songText?: string,
  id?: number,
  base64File?: string | null,
  duration: number,
  song_title: string;
  composers: ILyricInfo[];
  genre: IGenreInfo[];
  mood: IMoodInfo[];
  tempo: string | any;
  type?: string;
  tags: ITagsData[];
  campaignCode: ICampaignCode[];
  language: string;
}
