import API from "../http";
import { IAccount } from "../../pages/types";
import { IData } from "../../components/PasswordUpdate/PasswordUpdate";

const makeRequest = async (method: keyof typeof API, endpoint: string, data?: any) => {
  try {
    return await API[method](endpoint, data);
  } catch (error) {
    return error;
  }
};

interface IGetSong {
  search: string;
  collectionId: string | number;
  mood: string;
  genre: string;
  tempo: string;
}

interface ISongCollection {
  collectionId: number | string | any,
  addSongIds?: number[],
  deleteSongIds?: number[]
}

export const postTranscribing = async (lyricData: any) =>
  makeRequest("post", "/songs/transcribe", lyricData);
export const postUpload = async (lyricData: any) =>
  makeRequest("post", "/songs/upload", lyricData);

export const getProfile = async () => makeRequest("get", "/settings/profile");
export const postProfile = async (data: IAccount) => makeRequest("post", "/settings/profile", data);
export const postUpdatePassword = async (data: IData) => makeRequest("post", "/settings/changePassword", data);
export const getLyrics = async (lyricId: any) =>
  makeRequest("get", `/songs/transcribe?songId=${lyricId}`);
export const getCurrentCollection = async (collectionId: any) =>
  makeRequest("get", `/collection/songs?collectionId=${collectionId}`);
export const getSongs = async (data: IGetSong) => {
  return makeRequest("get",
    `/songs?searchBy=${data.search}&genreFilter=${data.genre}&moodFilter=${data.mood}&tempoFilter=${data.tempo}&collectionId=${data.collectionId}`
  );
};
export const postInquire = async (data: any) => makeRequest("post", "/songs/inquire", data);
export const getCollections = async () => makeRequest("get", "/collection");

export const postCollection = async (data: any) => makeRequest("post", "/collection", data);
export const postSongCollection = async (data: ISongCollection) => makeRequest("post", "/collection/songs", data);


export const deleteSong = async (id: number) => makeRequest("delete", `/songs/upload?songId=${id}`);
export const deleteCollection = async (id: number | null | undefined) => makeRequest("delete", `/collection?collectionId=${id}`);
