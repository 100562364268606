import React from "react";

interface Props {
  size?: number;
  handleClick?: () => void;
}

export const AccountImage: React.FC<Props> = ({ size = 20, handleClick }) => {
  return (
    <svg onClick={handleClick} width={size} height={size} viewBox="0 0 18 18" fill="none">
      <circle cx="9" cy="9" r="8.5" fill="white" stroke="black" />
      <g clipPath="url(#clip0_843_923)">
        <path
          d="M12.8432 5.08809C12.7643 5.01967 12.6596 4.98889 12.5562 5.0036L7.51289 5.72407C7.33541 5.74943 7.2036 5.90143 7.2036 6.08071V7.52167V10.3043C6.99022 10.18 6.74252 10.1084 6.47827 10.1084C5.68106 10.1084 5.03247 10.757 5.03247 11.5542C5.03247 12.3514 5.68105 13 6.47825 13C7.27545 13 7.92405 12.3514 7.92405 11.5542V7.8341L12.2469 7.21656V9.58381C12.0335 9.4595 11.7858 9.38792 11.5216 9.38792C10.7244 9.38792 10.0758 10.0365 10.0758 10.8337C10.0758 11.6309 10.7244 12.2795 11.5216 12.2795C12.3188 12.2795 12.9674 11.6309 12.9674 10.8337V6.80118V5.36023C12.9674 5.25579 12.9221 5.15651 12.8432 5.08809Z"
          fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_843_923">
          <rect width="8" height="8" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};





