import React, { FC } from "react";
import { Box, Typography } from "@mui/material";
import { closeSnackbar, SnackbarContent } from "notistack";
import styles from "./Notification.module.css";
import { Tick } from "../../assets/images/file/files";
import { ICustomSnackbarContent, ReportCompleteProps } from "./types";

const CustomSnackbarContent: FC<ICustomSnackbarContent> = ({ message, onClose }) => {
  return (
    <SnackbarContent className={styles.snackbar}>
      <Box className={styles.snackbarContent}>
        <Box className={styles.snackbarLeft}>
          <Tick />
          <Typography variant={"h3"}>{message}</Typography>
        </Box>
        <Typography variant={"h4"} onClick={onClose}>Close</Typography>
      </Box>
    </SnackbarContent>
  );
};

const CustomSnackBar = React.forwardRef<HTMLDivElement, ReportCompleteProps>(
  (props, ref) => {
    const { id, message } = props;
    return (
      <SnackbarContent ref={ref} role="alert">
        <CustomSnackbarContent
          message={message as string}
          id={id}
          onClose={() => closeSnackbar(id)}
        />
      </SnackbarContent>
    );
  }
);

export default CustomSnackBar;
