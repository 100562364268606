import React from "react";

interface Props {
  play?: boolean;
  handleClick?: () => void;
  fill?: string;
  stroke?: string;
}

export const Pause: React.FC<Props> = ({ play, fill, stroke, handleClick }) => {
  return (
    <svg onClick={handleClick} width="32" height="32" viewBox="0 0 32 32" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill={fill || "white"} />
      <mask id="mask0_79_3497" maskUnits="userSpaceOnUse" x="4" y="4" width="24" height="24">
        <rect x="4" y="4" width="24" height="24" fill="#D9D9D9" />
      </mask>
      {play ?
        <g mask="url(#mask0_79_3497)">
          <path d="M10 23V9H14V23H10Z" fill={stroke || "black"} />
          <path d="M18 23V9H22V23H18Z" fill={stroke || "black"} />
        </g>
        :
        <g mask="url(#mask0_942_23)">
          <path d="M23 16L11 22.9282V9.07181L23 16Z" fill={stroke || "black"} />
        </g>
      }
    </svg>
  );
};
