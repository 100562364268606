import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { Arrows } from "../../assets/images/file/files";
import styles from "./AdaptiveScreen.module.css";

const AdaptiveScreen: React.FC = () => {
  return (
    <Container>
      <Box className={styles.available}>
        <Arrows />
        <Typography variant={"h1"}>
          Oops! The admin panel is not available at this resolution
        </Typography>
        <Typography variant={"h3"}>
          Please resize your browser to be at least 960px wide
        </Typography>
      </Box>
    </Container>
  );
};

export default AdaptiveScreen;
