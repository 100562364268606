import { ICollection } from "../components/Tabs/Tabs";

export interface IDataTable {
  setUpdate?: (e: boolean) => void;
  songs: IDataLyrics[] | null,
  filters?: any,
  count?: number | null,
  isOpen: (e: boolean) => void;
  setEditSong?: (e: boolean) => void;
  setOpenDescription?: (e: boolean) => void;
  setCollection?: (e: any) => void
  fetchData?: () => void;
  setCurrentSong?: any,
  openFilters?: boolean,
  loading?: boolean,
  collection?: ICollection
  dataCollect?: ICollection[]
}

export interface IDataLyrics {
  id: number,
  userId?: number,
  title: string,
  genre: string[],
  mood: string[],
  tempo: string | any,
  keyLyrics: string[],
  campaignCode: string[],
  createdAt: string,
  language: string,
  filePath: string,
  fileName: string,
  fileSize: string,
  duration: any,
  songText: string,
  artists: any[]
}


export interface IAccount {
  email: string,
  legalName: string,
  artistName: string,
  phone: string,
  passwordUpdated?: string
}

export const Account = {
  email: "",
  legalName: "",
  artistName: "",
  phone: "",
  password: ""
};

