import { CustomContentProps, SnackbarOrigin } from "notistack";

export const snackbarPosTopRight: SnackbarOrigin = {
  vertical: "top",
  horizontal: "right"
};

export interface ICustomSnackbarContent {
  type?: "success";
  message: string;
  id?: number | string;
  onClose: () => void;
}

export interface ReportCompleteProps extends CustomContentProps {
  type: "success";
}
