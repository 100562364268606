import React from "react";
import { Box, ButtonGroup, Grid, Typography } from "@mui/material";
import { OptionGenre, OptionMood, OptionTempo } from "../../const/Options";
import styles from "./Filter.module.css";
import { IDataFilters } from "../../pages/Dashboard/Dashboard";
import { CustomButton } from "../index";

type Options = string[];

export interface IFilter {
  setDataFilters?: any;
  dataFilters?: any;
  fetchData?: () => void;
  handleClose?: () => void;
  handleClick?: () => void;


}

const Filter: React.FC<IFilter> = (props) => {
  const { setDataFilters, dataFilters, handleClick, handleClose, fetchData } = props;
  const renderOptions = (category: keyof IDataFilters, options: Options) => (
    options.map((option, index) => (
      <Typography
        key={index}
        className={dataFilters[category].includes(option) ? styles.active : ""}
        variant={"h6"}
        onClick={() => {
          setDataFilters((prev: { [x: string]: any; }) => ({
            ...prev,
            [category]: prev[category].includes(option)
              ? prev[category].filter((item: string) => item !== option)
              : [...prev[category], option]
          }));
        }}
      >
        {option}
      </Typography>
    ))
  );

  const handleFilters = () => {
    fetchData?.();
    handleClose?.();
  };


  return (
    <Box className={styles.filters}>
      <Grid columns={1}>
        <Grid item xs={4}>
          <Typography variant="h4" mb={2}>
            Genre
          </Typography>
          <div className={styles.filtersRows}>
            {renderOptions("genre", OptionGenre)}
          </div>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4" mb={2} mt={2}>
            Mood
          </Typography>
          <div className={styles.filtersRows}>
            {renderOptions("mood", OptionMood)}
          </div>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4" mb={2} mt={2}>
            Tempo
          </Typography>
          <div className={styles.filtersRows}>
            {renderOptions("tempo", OptionTempo)}
          </div>
        </Grid>
      </Grid>
      <ButtonGroup className={styles.buttons}>
        <CustomButton color={"black"} size={"small"} onClick={handleFilters}>
          Apply
        </CustomButton>
        <Typography variant={"h5"} onClick={handleClick}>Clear Filters</Typography>
      </ButtonGroup>
    </Box>
  );
};

export default Filter;
