import React, { useContext, useEffect, useState } from "react";
import {
  ArrowIcon,
  Close,
  DeleteIcon,
  Dots,
  EditIcon,
  Play,
  PlusIcon,
  SearchIcon,
  Time
} from "../../assets/images/file/files";
import styles from "./Table.module.css";
import { AudioContext } from "../../context/AudioContext";
import clsx from "clsx";
import { CustomButton, CustomInput, DeleteModal, Loader, Popup, Waveform } from "../index";
import { secondsToMMSS } from "../../utils/format";
import { IDataTable } from "../../pages/types";
import { deleteCollection, deleteSong, postCollection, postSongCollection } from "../../services/api/lyricsAPI";
import { enqueueSnackbar } from "notistack";
import { snackbarPosTopRight } from "../Notification/types";
import {
  Box,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import helper from "../../const/helper";
import { GroupIcon } from "../../assets/images/file";
import ModalTable from "./Modal/ModalTable";

interface IDelete {
  song?: any,
  open: boolean,
  loading?: boolean,
}

export interface IModal {
  open: boolean,
  type: "edit" | "delete" | "create" | "",
  songId?: number
}

const CustomTable: React.FC<IDataTable> = (props) => {
  const {
    setUpdate,
    songs,
    filters,
    collection,
    setCollection,
    openFilters,
    dataCollect,
    setOpenDescription,
    loading,
    count,
    setEditSong,
    fetchData,
    isOpen,
    setCurrentSong
  } = props;
  const { handleToggleAudio, clearContext, currentTrack, isPlaying } = useContext(AudioContext) || {};
  const [editLyric, setEditLyric] = useState<any>();
  const [addCollect, setAddCollect] = useState<any>();
  const [isDelete, setIsDelete] = useState<IDelete>({
    song: null,
    open: false,
    loading: false
  });
  const role = localStorage.getItem(helper.ROLE);
  const [collectionData, setCollectionData] = useState<any | null>(null);
  const [search, setSearch] = useState<string>("");
  const [modalData, setModalData] = useState<IModal>({ open: false, type: "" });
  const [anchorEl, setAnchorEl] = React.useState<HTMLTableCellElement | null>(null);
  const [anchorElInner, setAnchorElInner] = React.useState<HTMLTableCellElement | null>(null);
  const [anchorCollect, setAnchorCollect] = React.useState<HTMLTableCellElement | null>(null);
  useEffect(() => {
    (collection === undefined || collection.id) && setCollectionData(collection);
  }, [collection]);
  const handleEdit = (event: React.MouseEvent<HTMLTableCellElement>, id: any) => {
    setUpdate?.(true);
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setEditLyric(id);
  };
  const handleAddCollection = (event: React.MouseEvent<HTMLTableCellElement>, id: any) => {
    setUpdate?.(true);
    setAnchorElInner(anchorElInner ? null : event.currentTarget);
    setAddCollect(id);
  };
  const handleEditCollect = (event: React.MouseEvent<HTMLTableCellElement>) => {
    setAnchorCollect(anchorEl ? null : event.currentTarget);
  };
  const checkFilters = () => filters.search || openFilters || [filters.genre, filters.mood, filters.tempo].some(arr => arr.length > 0);
  const handleCloseModal = () => {
    setIsDelete({ open: !isDelete.open });
  };
  const handleClose = () => {
    setUpdate?.(false);
    setAnchorEl(null);
    setAnchorCollect(null);
    setAnchorElInner(null);
  };
  const handleDeleteSong = async (id: number) => {
    setIsDelete((prev) => ({ ...prev, loading: true }));
    try {
      const songsData = await deleteSong(id);
      if (songsData?.msg) {
        enqueueSnackbar("Successfully deleted", {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
        setOpenDescription?.(false);
        fetchData?.();
        clearContext?.();
      } else {
        enqueueSnackbar("Not deleted", {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
      }
    } catch (error) {
      console.error("Error fetching songs:", error);
    } finally {
      setIsDelete((prev) => ({
        ...prev,
        open: false,
        loading: false
      }));
    }
  };
  const handleDeleteCollection = async (id: number | null | undefined) => {
    try {
      const res = await deleteCollection(id);
      if (res?.msg) {
        enqueueSnackbar("Successfully deleted", {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
        setCollection?.({ id: null, name: "" });
        setCollectionData(null);
        setModalData({ ...modalData, open: false, type: "" });
        fetchData?.();
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleSongCollection = async (songId: number | any, collectionId: number | any, collectionName: string) => {
    try {
      const res = await postSongCollection({
        collectionId: collectionId,
        addSongIds: [songId]
      });
      if (res.msg) {
        handleClose();
        setCollection?.({ id: null, name: "" });
        setModalData({ ...modalData, open: false, type: "" });
        fetchData?.();
        setCollectionData(null);
        enqueueSnackbar(`Song successfully added to ${collectionName}`, {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };
  const handleDelele = async (songId: number) => {
    try {
      const res = await postSongCollection({
        collectionId: collection?.id,
        deleteSongIds: [songId]
      });
      if (res.msg) {
        handleClose();
        enqueueSnackbar(`Song successfully deleted from ${collection?.name}`, {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
        fetchData?.();
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };
  const handleEditCollection = async (id: number | null | undefined, name: string | null | undefined) => {
    const songId = modalData.songId;
    try {
      const res = await postCollection({ collectionName: name, collectionId: id });
      if (res.id) {
        await handleSongCollection(songId, res.id, res.name);
        const successMessage = id ? "Successfully edited" : `Collection '${name}' successfully created`;
        enqueueSnackbar(successMessage, {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
        handleClose();
        setCollection?.({ id, name });
        setModalData({ ...modalData, open: false, type: "", songId: NaN });
        fetchData?.();
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleOpenModal = (id: number) => {
    setModalData({ ...modalData, open: true, type: "create", songId: id });
  };
  return (
    <TableContainer component={Paper}>
      {!loading ?
        <>
          <div className={collection?.id ? styles.tableCollection : styles.tableCollectionClosed}
               id={"collection"}>{collection?.name}
            <GroupIcon onClick={(event) => handleEditCollect(event)} />
            <Popup id={"collection"} anchorEl={anchorCollect} open={true}
                   handleClose={handleClose}>
              <Box className={styles.tablePopup}><>
                <Typography variant="h3" onClick={() => {
                  handleClose();
                  setModalData({ ...modalData, open: true, type: "edit" });
                }}><EditIcon /><span>Edit</span></Typography>
                <Typography variant="h3" onClick={() => {
                  setModalData({ ...modalData, open: true, type: "delete" });
                  handleClose();
                }}><DeleteIcon /><span>Delete</span></Typography>
              </>
              </Box>
            </Popup>
          </div>

          <Table sx={{ minWidth: 550 }} aria-label="sticky table" className={styles.table}>
            {count ? <><TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell style={{ paddingLeft: "2rem" }}>Title</TableCell>
                  <TableCell align="left">Composers</TableCell>
                  <TableCell align="left">Key Lyrics</TableCell>
                  <TableCell align="left">Campaign</TableCell>
                  <TableCell align="left">Tempo</TableCell>
                  <TableCell align="center"><Time /></TableCell>
                  {role !== "Content creator" && <TableCell align="right"></TableCell>}
                </TableRow>
              </TableHead>
                <TableBody>
                  {songs && songs.map((row) => (
                    <TableRow key={row.id} hover
                              className={clsx(styles.tableRow, row.id === currentTrack?.id && styles.playing)}>
                      <TableCell component="th" scope="row" className={styles.rowFirst}>
                        <IconButton onClick={() => {
                          handleToggleAudio && handleToggleAudio(row, songs);
                          setCurrentSong(row);
                          setOpenDescription?.(true);
                        }}>
                          {(row.id === currentTrack?.id) && isPlaying ? <Waveform /> : <Play />}
                        </IconButton>
                        <Tooltip title={row.title}><span className={styles.rowFirstTitle}>{row.title}</span></Tooltip>
                      </TableCell>
                      <TableCell align="left">
                        <Tooltip title={row?.artists?.map(e => e.name).join(", ")}>
                          <span>{row?.artists?.map(e => e.name).join(", ")}</span></Tooltip>
                      </TableCell>
                      <TableCell align="left">{row?.keyLyrics?.map(key => key).join(", ")}</TableCell>
                      <TableCell align="left">{row?.campaignCode?.map(code => code).join(", ")}</TableCell>
                      <TableCell align="left">{row?.tempo} BPM</TableCell>
                      <TableCell align="center">{secondsToMMSS(row.duration)}</TableCell>
                      <TableCell style={{ cursor: "pointer" }} align="center"
                                 onClick={(event) => handleEdit(event, row.id)}><Dots /></TableCell>
                      <Popup id={row.id} anchorEl={anchorEl} open={editLyric === row.id}
                             handleClose={handleClose}>
                        <Box className={styles.tablePopup}>
                          {role !== "Content creator" && (
                            <>
                              <Typography variant="h3" onClick={() => {
                                handleClose();
                                setCurrentSong(row);
                                setEditSong?.(true);
                              }}>
                                <EditIcon />
                                <span>Edit</span>
                              </Typography>
                              <Typography variant="h3" onClick={() => {
                                handleClose();
                                setIsDelete({ open: !isDelete.open, song: row });
                              }}>
                                <DeleteIcon />
                                <span>Delete</span>
                              </Typography>
                            </>
                          )}
                          {collection?.id ?
                            <Typography variant="h3" onClick={() => handleDelele(row.id)}>
                              <DeleteIcon />
                              <span>Remove from collection</span>
                            </Typography> :
                            <Typography variant="h3" onClick={(event: any) => handleAddCollection(event, row.id)}>
                              <PlusIcon /> <span> Add to collection</span> <ArrowIcon /></Typography>}
                        </Box>
                        <Popup id={row.id} anchorEl={anchorElInner}
                               margin={220}
                               open={addCollect === row.id}
                               handleClose={handleClose}>
                          <Box className={styles.tableSubmenu}>
                            <CustomInput
                              className={styles.tableSubmenuInput}
                              id="popupSearch"
                              endIcon={search && <Close />}
                              type={"text"}
                              handleClear={() => setSearch("")}
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              startIcon={(
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              )}
                              placeholder={"Find a collection"} />
                            <div className={styles.tableSubmenuHead}
                                 onClick={() => handleOpenModal(row.id)}>
                              <PlusIcon />
                              <span>New collection</span>
                            </div>
                            <ul className={styles.tableSubmenuList}>
                              {dataCollect?.filter(collection => collection.name !== "All Music" && collection.name.toLowerCase().includes(search.toLowerCase())).map(e => (
                                <li key={e.id} onClick={() => handleSongCollection(row.id, e.id, e.name)}>
                                  {e.name}</li>))}
                              {(dataCollect?.length !== 1 && dataCollect?.filter(e => e.name.toLowerCase().includes(search.toLowerCase())).length === 0) && (
                                <span className={styles.tableSubmenuListNoData}>No collections found</span>
                              )}
                            </ul>
                          </Box>
                        </Popup>
                      </Popup>

                    </TableRow>
                  ))}
                </TableBody></> :
              <Box className={styles.tableEmpty}>
                {collection?.id ? <>
                  <Typography mb={4} variant={"h1"}>Start adding songs to this collection</Typography>
                  <CustomButton size={"small"} color={"white"} colorText={"black"}
                                onClick={() => setCollection?.({ id: null, name: "" })}>
                    Go to all music
                  </CustomButton></> : <>
                  <Typography mb={2} variant={"h1"}>
                    {checkFilters() && !isDelete.loading ? "No songs found" : role !== "Content creator" ? "Start adding songs" : "No songs have been added yet"}
                  </Typography>
                  <Typography mb={3}>
                    {(checkFilters() && !isDelete.loading) ? "Try adjusting your search or filter to find what you’re looking for." : role !== "Content creator" && "Upload audio file"}
                  </Typography>
                  {role !== "Content creator" && (!checkFilters() && !isDelete.loading) &&
                    <CustomButton size={"small"} color={"white"} colorText={"black"} startIcon
                                  onClick={() => isOpen(true)}>Add</CustomButton>}</>}
              </Box>}
            <DeleteModal open={isDelete.open} onClose={handleCloseModal} data={isDelete.song}
                         handleDelete={handleDeleteSong} loading={isDelete.loading} />
          </Table></> : <Box className={styles.loader}><Loader size /></Box>}
      <ModalTable modalData={modalData} collection={collection} collectionData={collectionData}
                  handleDeleteCollection={handleDeleteCollection}
                  handleEditCollection={handleEditCollection}
                  setCollectionData={setCollectionData} />
    </TableContainer>
  );
};
export default CustomTable;
