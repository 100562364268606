import React, { ReactNode } from "react";
import { FormControl, Typography } from "@mui/material";
import styles from "./Form.module.css";

type FormWrapperProps = {
  title?: string
  children: ReactNode
}

const Form: React.FC<FormWrapperProps> = (props) => {
  const { title, children } = props;
  return (
    <FormControl className={styles.form}>
      {title && <Typography variant={"h2"} className={styles.formTitle}>{title}</Typography>}
      <div className={styles.formContent}>
        {children}
      </div>
    </FormControl>
  );
};

export default Form;
