import React from "react";
import { ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { LyricsIcon } from "../../assets/images/file/files";
import styles from "./LyricsBlock.module.css";
import { formatDuration, formatFileSize } from "../../utils/format";

interface ILyricsBlock {
  data: any,
  deleteModal?: boolean
}

const LyricsBlock: React.FC<ILyricsBlock> = (props) => {
  const { data, deleteModal } = props;
  return (
    <ListItem className={styles.lyric}>
      <ListItemAvatar>
        <LyricsIcon />
      </ListItemAvatar>
      <ListItemText primary={<span className={styles.lyricTitle}>{deleteModal ? data?.fileName : data.file.name}</span>}
                    secondary={
                      <Typography className={styles.lyricInner}>
                        <Typography variant={"h3"}>
                          File
                          size: <span>{formatFileSize(deleteModal ? data?.fileSize : data?.file?.size)}</span></Typography>
                        <Typography variant={"h3"}>Duration: <span>{formatDuration(data?.duration)}</span></Typography>
                      </Typography>
                    } />
    </ListItem>
  );
};

export default LyricsBlock;
