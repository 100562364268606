import React, { useState } from "react";
import { Box, ButtonGroup, FormControl, Typography } from "@mui/material";
import { CustomButton, CustomInput } from "../index";
import styles from "./PasswordUpdate.module.css";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/const/routes";
import { useFormik } from "formik";
import { CURRENT_PASSWORD, PASSWORD_CHANGE_PATTERN } from "../../const/regex";
import { postUpdatePassword } from "../../services/api/lyricsAPI";

interface IProps {
  onClose?: () => void;
  getAccount?: () => void;
}

export interface IData {
  currentPassword: string,
  newPassword?: string,
  repeatPassword?: string
}

const Data = {
  currentPassword: "",
  newPassword: "",
  repeatPassword: ""
};

const PasswordUpdate: React.FC<IProps> = (props) => {
  const { onClose, getAccount } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [error, setError] = useState<string>("");
  const formik = useFormik<IData>({
    initialValues: Data,
    validationSchema: step === 1 ? CURRENT_PASSWORD : PASSWORD_CHANGE_PATTERN,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoading(true);
      if (step === 1) {
        await postUpdatePassword({ currentPassword: values.currentPassword })
          .then((res) => {
            setLoading(false);
            if (res.msg) {
              setStep(step + 1);
            } else {
              setError("Incorrect password");
            }
          });
      } else if (step === 2) {
        await postUpdatePassword({
          currentPassword: values.currentPassword,
          newPassword: values.newPassword
        }).then((res) => {
          res.msg && onClose?.();
          getAccount?.();
          setLoading(false);
        });
      }
    }
  });
  return (
    <Box className={styles.passwordUpdate}>
      <FormControl component="form" onSubmit={formik.handleSubmit}>
        {step === 1 && (

          <Box className={styles.passwordUpdateFirst}>
            <Typography variant={"h1"}>Confirm your existing password</Typography>
            <CustomInput
              label="Password"
              id="password"
              type={"password"}
              placeholder="Enter password"
              value={formik.values.currentPassword}
              onChange={formik.handleChange("currentPassword")}
              error={(formik.touched.currentPassword && Boolean(formik.errors.currentPassword)) || Boolean(error)}
              helperText={(formik.touched.currentPassword && formik.errors.currentPassword) || error}
            />
            <ButtonGroup className={styles.passwordUpdateButton}>
              <CustomButton colorText={"black"} onClick={onClose}>Cancel</CustomButton>
              <CustomButton color={"black"} type={step === 1 ? "submit" : "button"}
                            loading={loading}>Confirm</CustomButton>
            </ButtonGroup>
            <Typography variant={"h4"} onClick={() => navigate(routes.forgotPassword)}>Forgot password?</Typography>
          </Box>
        )}
        {step === 2 && (
          <Box className={styles.passwordUpdateSecond}>
            <Typography variant={"h1"}>Enter your new password</Typography>
            <CustomInput
              id={"password"}
              type={"password"}
              placeholder="Enter password"
              value={formik.values.newPassword}
              onChange={formik.handleChange("newPassword")}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
            />
            <CustomInput
              id={"new-password"}
              type={"password"}
              placeholder="Confirm password"
              value={formik.values.repeatPassword}
              onChange={formik.handleChange("repeatPassword")}
              error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
              helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
            />
            <ButtonGroup className={styles.passwordUpdateButton}>
              <CustomButton colorText={"black"} onClick={onClose}>Cancel</CustomButton>
              <CustomButton color={"black"} type={step === 2 ? "submit" : "button"}
                            loading={loading}>Save</CustomButton>
            </ButtonGroup>
          </Box>
        )}
      </FormControl>
    </Box>
  );
};

export default PasswordUpdate;
