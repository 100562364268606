import React from "react";

interface Props {
  onClick?: (e: any) => void;
}

export const GroupIcon: React.FC<Props> = ({ onClick }) => {
  return (
    <svg onClick={onClick} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="11" fill="white" fillOpacity="0.12" />
      <circle cx="6.5" cy="11.5" r="1.5" fill="white" />
      <circle cx="11" cy="11.5" r="1.5" fill="white" />
      <circle cx="15.5" cy="11.5" r="1.5" fill="white" />
    </svg>
  );
};




