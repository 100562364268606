import API from "../http";

export const userLogin = async (body: any) => {
  try {
    return await API.post("/login", body);
  } catch (error) {
    return error;
  }
};
// export function userLogout(isUser = null) {
//   if (isUser) {
//     API
//       .post("/user/logout")
//       .then(() => forceLogout())
//       .catch(() => forceLogout());
//   } else {
//     forceLogout();
//   }
// }

export function forceLogout() {
  localStorage.clear();
  sessionStorage.clear();

}
