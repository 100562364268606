import React from "react";
import { Box } from "@mui/material";
import styles from "./Header.module.css";
import { Logo } from "../../assets/images/file";
import { useNavigate } from "react-router-dom";
import AudioProvider from "../../context/AudioContext";
import routes from "../../routes/const/routes";
import { HeaderContent } from "../../modules";

const Header: React.FC = () => {
  const navigate = useNavigate();
  return (
    <AudioProvider>
      <Box className={styles.header}>
        <Logo handleClick={() => navigate(routes.dashboard)} />
        <HeaderContent />
      </Box>
    </AudioProvider>
  );
};

export default Header;

