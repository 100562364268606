import React, { useState } from "react";
import Button from "@mui/material/Button";
import styles from "./Button.module.css";
import { Arrow, DropIcon, FilterIcon, Plus } from "../../assets/images/file";
import clsx from "clsx";

interface CustomButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "black" | "transparent" | "white" | "#E9E9E9";
  colorText?: "black" | "transparent" | "white";
  endIcon?: boolean;
  loading?: boolean;
  size?: "small" | "large";
  type?: "button" | "submit";
  startIcon?: boolean;
  height?: string;
  borderColor?: string;
  endLoading?: boolean;
  animation?: boolean;
  disabled?: boolean;
  filterIcon?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const {
    children,
    startIcon,
    onClick,
    animation,
    size = "large",
    loading,
    type = "button",
    borderColor,
    height,
    disabled,
    endIcon,
    colorText = "white",
    endLoading,
    filterIcon,
    color = "transparent"
  } = props;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const buttonStyle: React.CSSProperties = {
    backgroundColor: color,
    borderRadius: filterIcon ? "4px" : "50px",
    color: colorText,
    height: height,
    width: size === "small" ? "max-content" : "100%",
    border: `1px solid ${borderColor || colorText}`,
    padding: size === "small" ? "4px 16px" : "8px 16px",
    display: filterIcon ? "grid" : "flex",
    gridTemplateColumns: filterIcon ? "0fr 1fr 1fr" : "",
    textTransform: filterIcon ? "none" : "uppercase",
    justifyContent: filterIcon ? "space-between" : "center",
    boxShadow: isHovered ? "0px 0px 10px rgba(0, 0, 0, 0.2)" : "none"
  };

  const renderStartIcon = () => (
    startIcon && <Plus stroke={color !== "black" && isHovered ? color : colorText} />
  );

  const renderEndIcon = () => (
    endIcon && <Arrow /> ||
    endLoading && loaderIcon()
  );


  const renderFilterIcon = () => (
    filterIcon && <FilterIcon stroke={isHovered ? "#fff" : "#000"} />
  );
  const renderDropIcon = () => (
    <DropIcon stroke={isHovered ? "#fff" : "#000"} style={{ transform: animation && "rotate(180deg)" }} />
  );
  const loaderIcon = () => (
    <span className={clsx(styles.loader, color === "white" && styles.loaderBlack)}></span>
  );


  return (
    <Button
      className={clsx(styles.button, filterIcon || color === "white" && !animation ? styles.buttonTransparent : (animation && styles.buttonWhite))}
      size={size}
      type={type}
      disabled={disabled}
      style={buttonStyle}
      onClick={onClick}
      startIcon={filterIcon ? renderFilterIcon() : renderStartIcon()}
      endIcon={filterIcon ? renderDropIcon() : renderEndIcon()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {loading ? loaderIcon() : children}
    </Button>
  );
};


export default CustomButton;
