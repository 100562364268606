import React from "react";

interface Props {
  stroke?: string;
  size?: string;
}

export const Arrow: React.FC<Props> = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_119_483" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_119_483)">
        <path
          d="M12.5 19.2254L11.775 18.5254L17.8 12.5004H5.27502V11.5004H17.8L11.775 5.47539L12.5 4.77539L19.725 12.0004L12.5 19.2254Z"
          fill="#FAFAFA" />
      </g>
    </svg>
  );
};




