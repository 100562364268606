import React, { ReactNode } from "react";
import { Box, Fade, Modal, Typography } from "@mui/material";
import styles from "./Modal.module.css";
import { Close } from "../../assets/images/file/files";

export interface ModalProps {
  isOpen: boolean;
  setIsOpen?: any;
  onClose?: () => void;
  fetchData?: () => void;
  className?: string;
  deleteModal?: boolean;
  children?: ReactNode;
}

const CustomModal: React.FC<ModalProps> = (props) => {
  const {
    children,
    deleteModal,
    isOpen,
    onClose
  } = props;

  return (
    <Modal
      open={isOpen}
      slotProps={{ backdrop: { timeout: 100 } }}>
      <Fade in={isOpen}>
        <Box className={styles.modal}>
          {!deleteModal &&
            <Typography className={styles.modalClose} onClick={onClose}>
              <Close />
            </Typography>}
          {children}
        </Box>
      </Fade>
    </Modal>

  );
};

export default CustomModal;
