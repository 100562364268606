import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "../Table/Table.module.css";
import LyricsBlock from "../LyricsBlock/LyricsBlock";
import { CustomButton, CustomModal } from "../index";

interface IDeleteModal {
  open: boolean,
  onClose: () => void,
  data: any,
  loading?: boolean | any,
  handleDelete?: (id: number) => void,
}

const DeleteModal: React.FC<IDeleteModal> = (props) => {
  const { open, onClose, handleDelete, data, loading } = props;
  return (
    <CustomModal isOpen={open} onClose={onClose} deleteModal>
      <Box className={styles.tableModal}>
        <Typography mb={2} variant={"h2"}>
          Delete this song?
        </Typography>
        <LyricsBlock data={data} deleteModal />
        <Typography mb={3} mt={3} variant={"h4"}>
          This action is irreversible, and you won’t be able to recover this song after deleting it.
        </Typography>
        <Box className={styles.button}>
          <CustomButton color={"white"} colorText={"black"} onClick={onClose}>Cancel</CustomButton>
          <CustomButton loading={loading} color={"black"}
                        onClick={() => handleDelete?.(data?.id)}>Delete</CustomButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default DeleteModal;
