import React, { useEffect, useState } from "react";
import { Logo } from "../../assets/images/file";
import routes from "../../routes/const/routes";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import styles from "./Collection.module.css";
import CustomCollectionTable from "./components/CustomCollectionTable";
import { getCurrentCollection } from "../../services/api/lyricsAPI";
import AudioProvider from "../../context/AudioContext";
import { Player } from "../../components";
import { TrackDescription } from "../../modules";

interface IData {
  id: string,
  name: string,
  songs: object[]
}

const CollectionPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [currentSong, setCurrentSong] = useState<any>(null);
  const [data, setData] = useState<IData | null>(null);
  const getCollection = async () => {
    const res = await getCurrentCollection(params.id);
    console.log("res", res);
    if (res?.songs) {
      setData(res);
    } else {
      setData({
        id: "",
        name: "",
        songs: []
      });
    }
  };
  console.log("data", data);
  useEffect(() => {
    getCollection?.();
  }, []);
  return (
    <AudioProvider>
      <Container className={styles.root}>
        <Box className={styles.header}>
          <Logo handleClick={() => navigate(routes.login)} />
        </Box>
        <Box className={styles.table}>
          {openDescription && <TrackDescription currentSong={currentSong} setOpenDescription={setOpenDescription} />}
          <CustomCollectionTable data={data} setCurrentSong={setCurrentSong}
                                 setOpenDescription={setOpenDescription} />
          <Player setCurrentSong={setCurrentSong}
                  setOpenDescription={setOpenDescription} />
        </Box>
      </Container>
    </AudioProvider>
  );
};

export default CollectionPage;
