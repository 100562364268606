import React, { createContext, useCallback, useEffect, useRef, useState } from "react";
import { AudioContextProps, AudioProviderProps } from "./types";
import { IDataLyrics } from "../pages/types";

export const AudioContext = createContext<AudioContextProps | undefined>(undefined);
const AudioProvider: React.FC<AudioProviderProps> = ({ children, track, trackList = [] }) => {
  const audio = useRef(new Audio());
  const [state, setState] = useState({
    currentTrack: track,
    isPlaying: false,
    trackList: trackList
  });

  const loadAndPlay = useCallback(async () => {
    try {
      await audio.current.play();
    } catch (error) {
      console.error("Error playing audio:", error);
    }
  }, []);

  const handleLoadedMetadata = useCallback(() => {
    loadAndPlay();
    if (audio.current) {
      audio.current.removeEventListener("loadedmetadata", handleLoadedMetadata);
    }
  }, [loadAndPlay]);

  useEffect(() => {
    audio.current.addEventListener("ended", nextTrack);
    audio.current.addEventListener("loadedmetadata", handleLoadedMetadata);

    return () => {
      audio.current?.removeEventListener("ended", nextTrack);
      audio.current?.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [state.trackList, state.currentTrack, handleLoadedMetadata]);

  const clearContext = () => {
    setState((prevState) => ({
      ...prevState,
      currentTrack: track,
      isPlaying: false,
      trackList: trackList
    }));
    audio.current.pause();
  };

  const loadNewTrack = (newTrack: IDataLyrics) => {
    audio.current.src = newTrack.filePath;
    audio.current.load();
  };

  const handleToggleAudio = async (newTrack: IDataLyrics, data: any) => {
    if (state.currentTrack?.id === newTrack.id) {
      if (state.isPlaying) {
        audio.current.pause();
      } else {
        await audio.current.play();
      }
      setState((prevState) => ({
        ...prevState,
        isPlaying: !prevState.isPlaying
      }));
    } else if (newTrack) {
      setState((prevState) => ({
        ...prevState,
        currentTrack: newTrack,
        isPlaying: true,
        trackList: data
      }));
      if (newTrack.filePath) {
        loadNewTrack(newTrack);
      }
    }
  };

  const stopTrack = async () => {
    setState((prevState) => ({
      ...prevState,
      currentTrack: state.currentTrack,
      isPlaying: !state.isPlaying,
      trackList: state.trackList
    }));
    if (state.isPlaying) {
      audio.current.pause();
    } else {
      await audio.current.play();
    }
  };


  const nextTrack = async () => {
    const currentIndex = state.trackList.findIndex(
      (track) => track.id === state.currentTrack?.id);
    const nextIndex = (currentIndex + 1) % state.trackList.length;
    const nextTrack = state.trackList[nextIndex];
    await handleToggleAudio(nextTrack, state.trackList);
  };

  const prevTrack = async () => {
    const currentIndex = state.trackList.findIndex(
      (track) => track.id === state.currentTrack?.id);
    const prevIndex = (currentIndex - 1 + state.trackList.length) % state.trackList.length;
    const prevTrack = state.trackList[prevIndex];
    await handleToggleAudio(prevTrack, state.trackList);
  };

  const value: AudioContextProps = {
    audio: audio.current,
    currentTrack: state.currentTrack,
    isPlaying: state.isPlaying,
    trackList: state.trackList,
    clearContext,
    handleToggleAudio,
    nextTrack,
    stopTrack,
    prevTrack
  };

  return <AudioContext.Provider value={value}>
    <audio id="audio-context" ref={audio} />
    {children}
  </AudioContext.Provider>;
};

export default AudioProvider;

