import React, { useState } from "react";
import { Box, FormControl, Grid, Typography } from "@mui/material";
import { ActionArrow, Logo } from "../../assets/images/file";
import styles from "./Auth.module.css";
import routes from "../../routes/const/routes";
import { useNavigate } from "react-router-dom";
import { RegistrationConstant } from "../../const/Auth";
import Default from "../../assets/images/RegisterImage.png";
import { CustomButton, CustomInput } from "../../components";
import { useFormik } from "formik";
import { userRegistration } from "../../services/api/userAPI";
import { setRole, setToken, setUser } from "../../store/reducer";
import { useDispatch } from "react-redux";
import { RegistrationFormValues } from "./types";
import { REGISTRATION_PATTERN } from "../../const/regex";
import { getProfile } from "../../services/api/lyricsAPI";
import { capitalizeFirstLetter } from "../../utils/common";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";


const DataType: ITypeUser = { type: "" };

interface ITypeUser {
  type: "Composer/Songwriter" | "Content creator" | "";
}

const Registration: React.FC = () => {
  const dispatch = useDispatch();
  const [userType, setUserType] = useState<ITypeUser>(DataType);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");

  const formik = useFormik<RegistrationFormValues>({
    initialValues: RegistrationConstant,
    validationSchema: REGISTRATION_PATTERN,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const valuesData = {
          email: values.email,
          legalName: values.legalName,
          artistName: values.artistName,
          phone: values.phone.substring(1).toString(),
          password: values.password,
          role: userType.type
        };
        const req = await userRegistration(valuesData);
        if (req.jwt) {
          dispatch(setToken(req.jwt));
          dispatch(setRole(req.role));
          const userData = await getProfile();
          dispatch(setUser(userData));
          (req.jwt) && navigate(routes.dashboard);
        } else if (req.response) {
          setError(req.response.data.error || req.response.data.msg);
        }
      } catch (error) {
        setError("An error occurred while processing your request.");
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    }

  });
  const handleSelectType = (type: "Composer/Songwriter" | "Content creator") => {
    setUserType({ type });
  };
  return (
    <div className={styles.root}>
      <div>
        <img src={Default} alt={"Default"} loading="lazy" />
      </div>
      <div className={styles.container}>
        <Logo handleClick={() => setUserType({ type: "" })} />
        <Typography variant="h5" mt={6} mb={6} className={styles.title} gutterBottom>
          Sign Up
        </Typography>
        {userType.type ?
          <FormControl component="form" onSubmit={formik.handleSubmit}>
            <CustomInput
              value={formik.values.legalName}
              onChange={formik.handleChange("legalName")}
              onBlur={formik.handleBlur("legalName")}
              label={"Legal name"}
              id={"legal_name"}
              placeholder={"Legal Name"}
              error={formik.touched.legalName && Boolean(formik.errors.legalName)}
              helperText={formik.touched.legalName && formik.errors.legalName}
            />
            <CustomInput
              value={formik.values.artistName}
              onChange={formik.handleChange("artistName")}
              onBlur={formik.handleBlur("artistName")}
              label={userType.type === "Content creator" ? "Company" : "Artist name"}
              id={"artist_name"}
              placeholder={userType.type === "Content creator" ? "Company" : "Artist name"}
              error={formik.touched.artistName && Boolean(formik.errors.artistName)}
              helperText={formik.touched.artistName && formik.errors.artistName}
            />
            <Typography variant={"h4"}>Phone</Typography>
            <PhoneInput
              className={styles.phoneInput}
              defaultCountry="us"
              value={formik.values.phone}
              onChange={formik.handleChange("phone")}
              placeholder={"(201) 555-0123"}
            />
            {formik.touched.phone && Boolean(formik.errors.phone) &&
              <Typography className={styles.errorMsg}>{formik.errors.phone}</Typography>}
            <CustomInput
              value={formik.values.email}
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur("email")}
              label={"Email"}
              id={"email"}
              placeholder={"example@email.com"}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <CustomInput
              value={formik.values.password}
              label="Password"
              type={"password"}
              id="password"
              placeholder="••••••••"
              onChange={formik.handleChange("password")}
              onBlur={formik.handleBlur("password")}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <Typography className={styles.errorText} variant={"h2"}>
              {capitalizeFirstLetter(error)}
            </Typography>
            <Typography mt={4}>
              <CustomButton endIcon={!loading} loading={loading} color={"black"} type="submit">
                Get started
              </CustomButton>
            </Typography>
          </FormControl>
          :
          <Grid columns={1} className={styles.selectType}>
            <Typography variant={"h3"} mb={2}>Select the user type best describes you:</Typography>
            <Box className={styles.cards} onClick={() => handleSelectType("Composer/Songwriter")}>
              <div>
                <Typography variant={"h1"}>Composer/Songwriter</Typography>
                <Typography variant={"h5"}>Musicians who are seeking to upload their songs to <br /> monetize their
                  music</Typography>
              </div>
              <ActionArrow />
            </Box>
            <Box className={styles.cards} onClick={() => handleSelectType("Content creator")}>
              <div>
                <Typography variant={"h1"}>Content Creator</Typography>
                <Typography variant={"h5"}>Brands and individuals who are seeking songs for <br /> commercial and social
                  use</Typography>
              </div>
              <ActionArrow />
            </Box>
          </Grid>
        }
        <Typography variant="h5" mt={userType.type ? 4 : 6} className={styles.subtitle} gutterBottom>
          Already have an account? <u onClick={() => navigate(routes.login)}>Log in</u>
        </Typography>
      </div>
    </div>
  );
};

export default Registration;
