const Routes = {
  home: "",
  login: "/login",
  registration: "/registration",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  collection: "/collection/:id",
  dashboard: "/dashboard",
  accountSettings: "/account-settings"
};

export default Routes;
