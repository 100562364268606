import API from "../http";

const makeRequest = async (endpoint: string, data: any) => {
  try {
    return await API.post(endpoint, data);
  } catch (error) {
    return error;
  }
};

export const userRegistration = async (userData: any) =>
  makeRequest("/registration", userData);

export const forgotPassword = async (email: { email: string }) =>
  makeRequest("/reset_password/email", email);

export const resetPassword = async (userData: any) =>
  makeRequest("/reset_password", userData);

