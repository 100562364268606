import React from "react";

interface Props {
  handleClick?: any;
  className?: string;
}

export const PlusIcon: React.FC<Props> = (props) => {
  const { className, handleClick } = props;
  return (
    <svg onClick={handleClick} className={className} cursor={"pointer"} width="44" height="44" viewBox="0 0 44 44"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill="black" />
      <line x1="22.5" y1="13" x2="22.5" y2="31" stroke="white" />
      <line x1="31" y1="22.5" x2="13" y2="22.5" stroke="white" />
    </svg>
  );
};




