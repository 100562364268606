import React, { FormEvent, useRef } from "react";
import { Box, TextField, Typography } from "@mui/material";
import Tags from "./Tags";
import styles from "./TagInput.module.css";
import clsx from "clsx";

interface ITags {
  tags: any;
  title: string;
  name: string;
  disabled?: boolean,
  subtitle?: string;
  updateFields: (fields: Partial<any>) => void;
}

const TagInput: React.FC<ITags> = (props) => {
  const { name, tags, updateFields, disabled, title, subtitle } = props;
  const tagRef = useRef<HTMLInputElement>(null);
  const handleDelete = (value: string) => {
    const updatedTags = tags.filter((tag: string) => tag !== value);
    updateFields({ [name]: updatedTags });

  };
  const handleOnSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (tagRef.current?.value.trim() && tags.length < 3 && !tags.includes(tagRef.current.value)) {
      const newTags = [...tags, tagRef.current.value.trim()];
      updateFields({ [name]: newTags });
      tagRef.current.value = "";
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === " " && tagRef.current?.value.trim() !== "") {
      e.preventDefault();
      handleOnSubmit(e);
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Typography className={clsx(styles.formTags, disabled && styles.formTagsDisabled)}>
        {title}
        <span>{subtitle}</span>
      </Typography>
      <TextField
        inputRef={tagRef}
        disabled={tags.length === 3 || disabled}
        placeholder={"Type up to 3 tags"}
        onKeyDown={handleKeyDown}
      />
      <Box className={styles.tagsList}>
        {tags.map((data: string, index: number) => (
          <Tags data={data} handleDelete={handleDelete} key={index} />
        ))}
      </Box>
    </form>
  );
};

export default TagInput;
