import React from "react";

interface Props {
  stroke?: string;
}

export const Plus: React.FC<Props> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" stroke={props.stroke} fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_182_76" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_182_76)">
        <path d="M11.5 18.5V12.5H5.5V11.5H11.5V5.5H12.5V11.5H18.5V12.5H12.5V18.5H11.5Z" fill="white" />
      </g>
    </svg>

  );
};




