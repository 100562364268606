import React, { useEffect, useState } from "react";
import { Box, Container, FormControl, Grid, Typography } from "@mui/material";
import styles from "./AccountSettings.module.css";
import { CustomButton, CustomInput, CustomModal, Loader, PasswordUpdate } from "../../components";
import { useFormik } from "formik";
import { ACCOUNT_PATTERN } from "../../const/regex";
import { getProfile, postProfile } from "../../services/api/lyricsAPI";
import { Logo } from "../../assets/images/file";
import { Account, IAccount } from "../types";
import { setUser } from "../../store/reducer";
import { useDispatch } from "react-redux";
import { PhoneInput } from "react-international-phone";
import moment from "moment/moment";
import helper from "../../const/helper";
import { enqueueSnackbar } from "notistack";
import { snackbarPosTopRight } from "../../components/Notification/types";

const AccountSettings: React.FC = () => {
  const dispatch = useDispatch();
  const role = localStorage.getItem(helper.ROLE);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const formik = useFormik<IAccount>({
    initialValues: Account,
    validationSchema: ACCOUNT_PATTERN,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await postProfile({
          email: values.email,
          legalName: values.legalName,
          artistName: values.artistName,
          phone: values.phone.substring(1).toString()
        });
        enqueueSnackbar("Successfully edited", {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        getAccount?.();
        setLoading(false);
      }
    }
  });
  const getAccount = async () => {
    try {
      const res = await getProfile();
      formik.setValues?.(res);
      setLoader(false);
      dispatch(setUser(res));
    } catch (error) {
      console.error("Error fetching account details:", error);
    }
  };

  useEffect(() => {
    getAccount?.();
  }, []);
  return (
    <Container className={styles.containerSettings}>
      <Typography variant={"h1"} mt={4} mb={4}>Account Settings</Typography>
      {!loader ?
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <FormControl component="form" onSubmit={formik.handleSubmit}>
              {role !== "admin" &&
                <>
                  <CustomInput
                    value={formik.values.legalName}
                    label="Legal name"
                    id="legal_name"
                    placeholder={"Legal Name"}
                    onChange={formik.handleChange("legalName")}
                    onBlur={formik.handleBlur("legalName")}
                    error={formik.touched.legalName && Boolean(formik.errors.legalName)}
                    helperText={formik.touched.legalName && formik.errors.legalName}
                  />
                  <CustomInput
                    value={formik.values.artistName}
                    label={role === "Content creator" ? "Company" : "Artist name"}
                    id="artistName"
                    placeholder={role === "Content creator" ? "Company" : "Artist name"}
                    onChange={formik.handleChange("artistName")}
                    onBlur={formik.handleBlur("artistName")}
                    error={formik.touched.artistName && Boolean(formik.errors.artistName)}
                    helperText={formik.touched.artistName && formik.errors.artistName}
                  />
                  <Box className={styles.phoneInput}>
                    <Typography variant={"h5"}>Phone</Typography>
                    <PhoneInput
                      className={styles.phoneInputInner}
                      defaultCountry="us"
                      value={formik.values.phone}
                      onChange={formik.handleChange("phone")}
                      placeholder={"(201) 555-0123"}
                    />
                  </Box>
                  {formik.touched.phone && Boolean(formik.errors.phone) &&
                    <Typography className={styles.error}>{formik.errors.phone}</Typography>}
                </>}
              <CustomInput
                value={formik.values.email}
                onChange={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                label={"Email"}
                id={"email"}
                placeholder={"example@email.com"}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Box className={styles.password}>
                <Typography variant={"h1"}>Password</Typography>
                <Box className={styles.passwordInner}>
                  <Typography variant={"h4"}>Password last
                    updated: {moment(formik.values.passwordUpdated).format("MMMM D, YYYY")}</Typography>
                  <Typography variant={"h4"} onClick={() => setIsOpen(!isOpen)}>Update Password</Typography>
                </Box>
              </Box>
              <Typography mt={5} className={styles.buttonGroup}>
                <CustomButton loading={loading} color={"black"} type="submit">
                  Save Changes
                </CustomButton>
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={5} className={styles.accountLogo}><Logo stroke={"#ececec"} /></Grid>
        </Grid>
        : <Box className={styles.accountLoader}><Loader size /></Box>}
      <CustomModal isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} deleteModal>
        <PasswordUpdate getAccount={getAccount} onClose={() => setIsOpen(!isOpen)} />
      </CustomModal>
    </Container>

  );
};

export default AccountSettings;
