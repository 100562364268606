import React from "react";

interface Props {
  stroke?: string;
  style?: any;
}

export const DropIcon: React.FC<Props> = (props) => {
  const maskStyle: React.CSSProperties = {
    maskType: "alpha",
    stroke: props.stroke
  };
  const rectStyle: React.CSSProperties = {
    width: "24",
    height: "24",
    stroke: props.stroke
  };
  return (
    <svg style={props.style} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
         fill={props.stroke}>
      <mask id="mask0_18_3170" style={maskStyle} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect style={rectStyle} />
      </mask>
      <g mask="url(#mask0_18_3170)">
        <path d="M12 14.6998L6.69995 9.3998L7.39995 8.6748L12 13.2748L16.6 8.6748L17.3 9.3998L12 14.6998Z"
        />
      </g>
    </svg>
  );
};




