import React from "react";
import { CustomButton, CustomInput, CustomModal } from "../../index";
import { Box, ButtonGroup, Typography } from "@mui/material";
import styles from "../Table.module.css";
import { IModal } from "../CustomTable";

interface IModalTable {
  modalData: IModal;
  collection: any | null;
  collectionData: any | null;
  setCollectionData: (e: any) => void;
  handleEditCollection: (id: any, name: any) => void;
  handleDeleteCollection: (id: any) => void;
}

const ModalTable: React.FC<IModalTable> = (props) => {
  const {
    modalData,
    collection,
    handleDeleteCollection,
    handleEditCollection,
    collectionData,
    setCollectionData
  } = props;
  return (
    <CustomModal isOpen={modalData.open} deleteModal>
      <Box className={styles.collectionModal}>
        {modalData.type !== "create" && <Typography
          variant={"h1"}>{modalData.type === "delete" ? "Delete Collection?" : "Edit Collection"} </Typography>}
        {modalData.type === "delete" ?
          <span>Are you sure you want to delete {collection?.name}? <br />This action can’t be undone.</span> :
          <CustomInput
            label={modalData.type === "create" ? "New collection name" : "Сollection name"}
            placeholder="Collection name"
            value={collectionData?.name}
            onChange={(e) => setCollectionData((prev: any) => ({ ...prev, name: e.target.value }))}
          />}
        <ButtonGroup>
          <CustomButton
            color="#E9E9E9"
            colorText="black"
            borderColor="#E9E9E9"
            animation
            onClick={() => {
              setCollectionData({ ...collectionData, name: "" });
              modalData.type === "delete" ? handleDeleteCollection(collectionData?.id) : handleEditCollection(collectionData?.id, collectionData?.name);
            }}>Cancel
          </CustomButton>
          <CustomButton color="black"
                        onClick={() => modalData.type === "delete" ? handleDeleteCollection(collectionData?.id) : handleEditCollection(collectionData?.id, collectionData?.name)}>
            {modalData.type === "delete" ? "Delete" : "Save"}
          </CustomButton>
        </ButtonGroup>
      </Box>
    </CustomModal>
  );
};

export default ModalTable;
