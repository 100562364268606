import React, { useEffect, useRef, useState } from "react";
import { Box, Slider, Typography } from "@mui/material";
import styles from "./Form.module.css";
import { Pause, Volume } from "../../assets/images/file";
import { secondsToMMSS } from "../../utils/format";
import { TrackData } from "./FormSteps/Review";


const FormPlayer: React.FC<TrackData> = (props) => {
  const { isOpen, lyric, file, song_title, duration } = props;
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [volume, setVolume] = useState<number>(50);
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const audio = useRef(new Audio(lyric));
  const sliderCurrentTime = Math.round((currentTime / Math.max(duration || 1, 1)) * 100);
  const formattedDuration = secondsToMMSS(duration);
  const formattedCurrentTime = secondsToMMSS(currentTime);
  const handleToggleAudio = () => {
    if (file?.size) {
      if (!isPlaying) {
        audio.current.play();
      } else {
        audio.current.pause();
      }
      setPlaying(!isPlaying);
    }
  };
  const handleOffVolume = () => {
    if (audio.current) {
      const newVolume = volume === 0 ? 50 : 0;
      setVolume(newVolume);
      audio.current.volume = newVolume / 100;
    }
  };

  const handleChangeCurrentTime = (_: Event, value: number | number[]) => {
    if (!Array.isArray(value) && audio.current) {
      const time = Math.round((value / 100) * Math.max(duration || 1, 1));
      setCurrentTime(time);
      audio.current.currentTime = time;
    }
  };

  useEffect(() => {
    if (!isOpen) {
      // If the modal is not open, pause the audio and set isPlaying to false
      audio.current.pause();
      setPlaying(false);
    }

    const timeInterval = setInterval(() => {
      setCurrentTime(audio.current?.currentTime || 0);
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [audio, duration, isOpen]);

  return (
    <Box className={styles.formPlayer}>
      <div className={styles.formPlayerHead}>
        <Pause play={isPlaying} fill="#000" stroke="#fff" handleClick={handleToggleAudio} />
        <Typography component="span">{song_title}</Typography>
      </div>
      <div className={styles.formPlayerBody}>
        {formattedCurrentTime}
        <Slider
          className={styles.slider}
          color="info"
          step={1}
          min={0}
          max={100}
          value={sliderCurrentTime}
          onChange={handleChangeCurrentTime}
        />
        {formattedDuration}
        <Volume fill={"#000"} handleClick={handleOffVolume} off={volume !== 0} />
      </div>
    </Box>
  );
};

export default FormPlayer;
