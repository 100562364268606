import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Keys from "../const/helper";

interface AuthState {
  user?: any;
  role: string | null;
  token: string | null;
  message: string;
}

const token = localStorage.getItem(Keys.JWT_TOKEN);
const role = localStorage.getItem(Keys.ROLE);
const user_data = localStorage.getItem(Keys.USER_DATA);
const parsedUserData = user_data ? JSON.parse(user_data) : null;

const initialState: AuthState = {
  user: parsedUserData,
  token: token,
  role: role || "" as "Content creator" | "Composer/Songwriter" | null,
  message: ""
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem(Keys.JWT_TOKEN, action.payload);
      state.token = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      localStorage.setItem(Keys.USER_DATA, JSON.stringify(action.payload));
      state.user = action.payload;
    },
    setRole: (state, action: PayloadAction<"Content creator" | "Composer/Songwriter" | "">) => {
      localStorage.setItem(Keys.ROLE, action.payload);
      state.role = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    logoutFinish: (state) => {
      state.token = null;
      state.user = null;
      state.role = null;
      state.message = "";
      localStorage.removeItem(Keys.JWT_TOKEN);
      localStorage.removeItem(Keys.ROLE);
      localStorage.removeItem(Keys.USER_DATA);
    }
  }
});

export const { setUser, setRole, setToken, setMessage, logoutFinish } =
  authSlice.actions;

export default authSlice.reducer;
