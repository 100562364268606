import React, { useContext, useEffect, useState } from "react";
import styles from "../../../components/Table/Table.module.css";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { Play, Time } from "../../../assets/images/file/files";
import clsx from "clsx";
import { CustomButton, Loader, Waveform } from "../../../components";
import { secondsToMMSS } from "../../../utils/format";
import { AudioContext } from "../../../context/AudioContext";
import useResize from "../../../hooks/useResize";
import { Logo } from "../../../assets/images/file";
import { useNavigate } from "react-router-dom";
import routes from "../../../routes/const/routes";

interface IProps {
  data: any | object[];
  setCurrentSong: any;
  setOpenDescription?: (e: boolean) => void;
}

const CustomCollectionTable: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const innerWidth = useResize();
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      setScrollPosition(currentScrollPosition);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const { handleToggleAudio, currentTrack, isPlaying } = useContext(AudioContext) || {};
  const { data, setCurrentSong, setOpenDescription } = props;
  return (
    <>
      {data ? (
        <TableContainer>
          <div className={clsx(styles.tableCollections, scrollPosition > 70 && styles.tableCollectionsRemove)}
               id={"collection"}>

            <div
              className={clsx(styles.headerContent, scrollPosition > 70 && styles.headerContentBlock)}>
              {(innerWidth.innerWidth < 850) &&
                <Box className={clsx(styles.header, scrollPosition > 70 && styles.headerRemove)}>
                  <Logo />
                </Box>
              }
              {data?.name}
            </div>

            {innerWidth.innerWidth < 850 &&
              <div className={styles.customShapeDividerBottom}>
                <svg width="400" height="30" viewBox="0 0 400 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1079_13)">
                    <path
                      d="M0 0L0.233741 0.144023H400L399.766 12.9845C399.509 12.8933 399.175 12.7771 398.764 12.6403C397.682 12.2771 396.07 11.7638 393.953 11.1579C389.719 9.94755 383.459 8.36805 375.349 6.89592C359.13 3.95019 335.508 1.42654 305.881 3.10897C276.258 4.79141 240.666 11.5197 207.613 17.8215L206.157 18.0979C173.643 24.2982 143.741 30 124.506 30C85.4224 30 54.2092 22.4452 32.7722 14.8949C22.0536 11.1182 13.7793 7.34449 8.18754 4.51494C5.39166 3.10016 3.26647 1.92217 1.84174 1.0986C1.12938 0.686812 0.592133 0.364622 0.233741 0.144023C0.144792 0.0896087 0.0668589 0.0426491 0 0Z"
                      fill="#0F0F0F" />
                  </g>
                </svg>


              </div>
            }
          </div>

          {innerWidth?.innerWidth > 850 ? <>
              {data?.songs.length ?
                <Table sx={{ minWidth: 550 }} aria-label="sticky table" className={styles.table}>
                  <TableHead className={styles.tableHead}>
                    <TableRow>
                      <TableCell style={{ paddingLeft: "2rem" }}>Title</TableCell>
                      <TableCell align="left">Composers</TableCell>
                      <TableCell align="left">Key Lyrics</TableCell>
                      <TableCell align="left">Campaign</TableCell>
                      <TableCell align="left">Tempo</TableCell>
                      <TableCell align="center"><Time /></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data && data?.songs.map((row: any) => (
                      <TableRow key={row.id} hover
                                className={clsx(styles.tableRow)}>
                        <TableCell component="th" scope="row" className={styles.rowFirst}>
                          <IconButton
                            onClick={() => {
                              handleToggleAudio && handleToggleAudio(row, data?.songs);
                              setCurrentSong(row);
                              setOpenDescription?.(true);
                            }}
                          >
                            {(row.id === currentTrack?.id) && isPlaying ? <Waveform /> : <Play />}
                          </IconButton>
                          <Tooltip title={row.title}><span className={styles.rowFirstTitle}>{row.title}</span></Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title={row?.artists?.map((e: { name: any; }) => e.name).join(", ")}>
                            <span>{row?.artists?.map((e: { name: any; }) => e.name).join(", ")}</span></Tooltip>
                        </TableCell>
                        <TableCell align="left">{row?.keyLyrics?.map((key: any) => key).join(", ")}</TableCell>
                        <TableCell align="left">{row?.campaignCode?.map((code: any) => code).join(", ")}</TableCell>
                        <TableCell align="left">{row?.tempo} BPM</TableCell>
                        <TableCell align="center">{secondsToMMSS(row.duration)}</TableCell>
                      </TableRow>))}
                  </TableBody>
                </Table> :
                <Box className={styles.tableCollectionEmpty}>
                  {data.name !== "" && data.id !== "" ? "No songs have been added to this collection yet " : "This collection is unavailable"}
                  {(data.name === "" && data.id === "") &&
                    <CustomButton
                      onClick={() => navigate(routes.login)}
                      color="black"
                      size="small"
                      colorText="white"
                    >
                      Back to Login
                    </CustomButton>}
                </Box>}
            </> :
            <Box>
              <>
                {data?.songs.length > 0 ?
                  <div className={styles.tableMobile}>
                    {data && data?.songs.map((row: any, index: number) => (
                      <div className={clsx(styles.tableMobileInner, index % 2 && styles.tableMobileInnerGrey)}
                           key={row.id} onClick={() => {
                        handleToggleAudio && handleToggleAudio(row, data?.songs);
                      }}>
                        <div className={styles.tableMobileInnerLeft}>
                      <span>
                  {((row.id === currentTrack?.id) && isPlaying) && <IconButton><Waveform /></IconButton>}
                        {row.title}</span>
                          <span>{row?.artists?.map((e: { name: any; }) => e.name).join(", ")}</span>
                        </div>
                        <span className={styles.tableMobileInnerRight}>{secondsToMMSS(row.duration)}</span>
                      </div>
                    ))}
                  </div> :
                  <Box className={styles.tableCollectionEmpty}>
                    {data.name !== "" && data.id !== "" ? "No songs have been added to this collection yet " : "This collection is unavailable"}
                  </Box>}
              </>
            </Box>
          }
        </TableContainer>
      ) : (
        <Box className={styles.loader}>
          <Loader size />
        </Box>
      )}
    </>
  );
};

export default CustomCollectionTable;
