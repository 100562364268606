import React, { useEffect, useState } from "react";
import styles from "./UploadFile.module.css";
import { Box, Typography } from "@mui/material";
import { CustomButton, LyricsBlock, UploadTrack } from "../../components";
import { useMultistepForm } from "../../hooks/useMultistepForm";
import { BackArrow } from "../../assets/images/file";
import Language from "../../components/Form/FormSteps/Language";
import Review from "../../components/Form/FormSteps/Review";
import clsx from "clsx";
import { FormData } from "./types";
import Attributes from "../../components/Form/FormSteps/Attributes";
import { getLyrics, postTranscribing, postUpload } from "../../services/api/lyricsAPI";
import { convertAudioFileToBase64 } from "../../utils/format";
import { ModalProps } from "../../components/Modal/CustomModal";
import { INITIAL_DATA } from "../../components/Form/FormSteps/types";
import { enqueueSnackbar } from "notistack";
import { snackbarPosTopRight } from "../../components/Notification/types";
import ArtistInfo from "../../components/Form/FormSteps/ArtistInfo";

const UploadFile: React.FC<ModalProps> = (props) => {
  const { isOpen, fetchData, setIsOpen } = props;
  const [data, setData] = useState<FormData>(INITIAL_DATA);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const updateFields = (fields: Partial<FormData>) => {
    setData((prev) => ({ ...prev, ...fields }));
  };
  const handleLoading = (load: boolean) => {
    setLoader(load);
  };
  const sum = data.composers.reduce((accumulator, item) => accumulator + +item.split, 0);
  const { steps, setCurrentStepIndex, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    useMultistepForm([
      <UploadTrack key={1} {...data} updateFields={updateFields} />,
      <ArtistInfo key={2} {...data} updateFields={updateFields} sum={sum} error={error} />,
      <Attributes key={3} {...data} updateFields={updateFields} />,
      <Language key={4} {...data} updateFields={updateFields} loader={loader} duration={data?.duration} />,
      <Review key={5} {...data} isOpen={isOpen} updateFields={updateFields} />
    ]);
  const handleFileInputChange = async () => {
    const file = data?.file;
    if (file) {
      const base64String = await convertAudioFileToBase64(file);
      updateFields({ file, base64File: base64String.split(",")[1] });
    }
  };
  useEffect(() => {
    (data?.file && currentStepIndex === 0) && handleFileInputChange();
  }, [currentStepIndex, data?.file]);

  const handleSubmit = async () => {
    if (!isLastStep && currentStepIndex !== 3 && !(data?.type === "Instrumental" && currentStepIndex === 2)) {
      next?.();
    } else if (data?.type === "Instrumental" && currentStepIndex === 2) {
      setLoading(true);
      const transcribingRes = await postTranscribing({
        duration: Math.floor(+data?.duration),
        fileName: data?.file?.name,
        fileSize: data?.file?.size,
        type: data?.type,
        songBase64: data?.base64File
      });
      transcribingRes && updateFields({ lyric: transcribingRes?.songLink, id: transcribingRes?.songID });
      setLoading(false);
      setCurrentStepIndex(4);
    } else if (currentStepIndex === 3 && data?.base64File && data?.type === "Vocal") {
      handleLoading(true);
      const transcribingRes = await postTranscribing({
        duration: Math.floor(+data?.duration),
        fileName: data?.file?.name,
        fileSize: data?.file?.size,
        type: data?.type,
        songBase64: data?.base64File
      });
      transcribingRes && updateFields({ lyric: transcribingRes?.songLink, id: transcribingRes?.songID });
    } else {
      setLoading(true);
      const uploadRes = await postUpload({
        songId: data?.id,
        title: data?.song_title,
        artists: data?.composers,
        genre: data?.genre.map(e => e.genre),
        mood: data?.mood.map(e => e.mood),
        tempo: data?.tempo,
        keyLyrics: data?.tags.map(e => e),
        campaignCode: data?.campaignCode.map(e => e),
        language: data?.language,
        songText: data?.songText
      });
      if (uploadRes.msg) {
        setLoading(false);
        fetchData?.();
        setIsOpen(false);
        enqueueSnackbar("Successfully uploaded", {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
      } else {
        setLoading(false);
        setIsOpen(false);
        enqueueSnackbar("Song not added", {
          anchorOrigin: snackbarPosTopRight,
          variant: "success"
        });
      }
    }
  };

  useEffect(() => {
    const handleGetTranscriptions = async (id: number) => {
      try {
        const result = await getLyrics(id);
        if (result?.songText) {
          updateFields({ songText: result?.songText });
          next?.();
          handleLoading(false);
        }
      } catch (error) {
        console.error("Error fetching lyrics:", error);
        handleLoading(true);
      }
    };
    const fetchData = async () => (!data?.songText && loader && data?.id) && await handleGetTranscriptions(data?.id);
    fetchData?.();
    const intervalId = setInterval(fetchData, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [loader, data?.songText, data?.id]);


  useEffect(() => {
    let isDisabled;
    switch (currentStepIndex) {
      case 0:
        isDisabled = !data.file;
        break;
      case 1:
        isDisabled =
          data.song_title.length === 0 ||
          data.composers.length === 0 ||
          sum !== 100 ||
          data.composers.some(item => item.name === "" || item.split === 0);
        sum === 100 ? setError("") : setError(sum !== 0 ? "Spit total must equal 100 to continue." : "");
        break;
      case 2:
        isDisabled =
          data.genre.every(item => item.genre === "") ||
          data.type === "" ||
          data.mood.every(item => item.mood === "") ||
          data.tempo === "";
        break;
      case 3:
        isDisabled = !data.language.length;
        break;
      case 4:
        isDisabled = data.type === "Vocal" ? !data?.songText : false;
        break;
      default:
        isDisabled = false;
        break;
    }
    setDisabled(isDisabled);
  }, [currentStepIndex, data]);
  return (
    <Box className={styles.forms}>
      <Typography variant="h4"
                  className={styles.formCount}>Step {currentStepIndex + 1} / {steps.length}</Typography>
      {step}
      {!loader &&
        <Box className={clsx(styles.uploadBtn, isLastStep && styles.uploadBtnLast)}>
          {!isLastStep && <Typography onClick={() => back?.()}>{!isFirstStep && <BackArrow />}</Typography>}
          {data?.file &&
            <CustomButton disabled={disabled} color={"black"} size={"small"}
                          loading={loading}
                          endIcon={!(data?.type === "Instrumental" && currentStepIndex === 2) && !isLastStep}
                          onClick={handleSubmit}>
              {isLastStep ? "Finish" : `${currentStepIndex === 3 ? "Transcribe" : ((data.type === "Instrumental" && currentStepIndex === 2) ? "Finish" : "Next")}`}
            </CustomButton>}
        </Box>}
      {(data?.file && currentStepIndex !== 0 && currentStepIndex !== 4) &&
        <LyricsBlock data={data} />}
    </Box>
  );
};

export default UploadFile;
