import React, { ReactNode, useState } from "react";
import { FormControl, TextField, Typography } from "@mui/material";
import styles from "./CustomInput.module.css";
import { Visibility, VisibilityOff } from "../../assets/images/file/files";
import clsx from "clsx";

interface CustomInputProps {
  id?: string;
  label?: string;
  value?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  error?: any;
  startIcon?: ReactNode,
  endIcon?: ReactNode,
  handleClear?: () => void,
  onBlur?: any;
  helperText?: any;
  type?: "text" | "password" | "number" | "tel";
}

const CustomInput: React.FC<CustomInputProps> = (props) => {
  const {
    onChange,
    label,
    type,
    placeholder,
    className,
    handleClear,
    helperText,
    id,
    startIcon,
    endIcon,
    onBlur,
    error,
    value,
    ...children
  } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const renderEndIcon = () => (
    <Typography className={styles.inputEndIcon} onClick={handleClear}>{endIcon}</Typography>
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <div className={styles.input}>
      {label && <Typography className={styles.title}>{label}</Typography>}
      <FormControl
        className={clsx(styles.input, type === "password" && styles.inputPassword)}>
        <TextField
          className={clsx(styles.inputInner, className)}
          id={id}
          value={value}
          error={error}
          autoComplete="email"
          helperText={helperText}
          onBlur={onBlur}
          onChange={onChange}
          label={null}
          type={type === "password" && showPassword ? "text" : type}
          size={"small"}
          placeholder={placeholder}
          fullWidth
          InputProps={{
            classes: {
              notchedOutline: styles.border,
              focused: styles.background
            },
            startAdornment: startIcon,
            endAdornment: renderEndIcon()
          }}
          {...children}
        />
        {type === "password" &&
          <Typography variant={"h3"} onClick={handleClickShowPassword} className={styles.inputIcon}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </Typography>}

      </FormControl>

    </div>

  );
};

export default CustomInput;


