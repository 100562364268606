import React from "react";

interface Props {
  stroke?: string;
  handleClick?: () => void;
}

export const Logo: React.FC<Props> = ({ handleClick, stroke }) => {
  return (
    <svg onClick={handleClick} width="38" height="35" viewBox="0 0 38 35" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_180_1106)">
        <path
          d="M32.1483 33.2879C31.5756 33.837 31.0228 34.2682 30.3486 34.4775C29.7619 34.6601 29.1554 34.8196 28.5466 34.8902C27.778 34.9792 26.9953 35.0196 26.222 34.9907C24.3045 34.9179 22.5072 34.4428 20.9699 33.2382C19.6379 32.1955 18.8122 30.8348 18.3724 29.2175C18.0645 28.0858 17.9829 26.939 18.0727 25.783C18.2336 23.7194 18.9276 21.8594 20.4229 20.3681C21.37 19.4236 22.5177 18.7774 23.7995 18.3635C25.5712 17.7925 27.3931 17.6352 29.2382 17.7566C31.1149 17.8803 32.9239 18.2768 34.5429 19.2849C35.9192 20.1415 36.8686 21.3207 37.2593 22.9079C37.3305 23.1981 37.2698 23.2859 36.97 23.2859C34.7831 23.2894 32.5962 23.2859 30.4093 23.2894C30.2075 23.2894 30.0722 23.2189 30.0104 23.027C29.7246 22.1368 29.0621 21.7831 28.171 21.7796C27.2484 21.7761 26.6163 22.2386 26.2267 23.042C25.9153 23.6824 25.7555 24.3703 25.7298 25.0743C25.7007 25.8812 25.6727 26.6962 25.7473 27.4985C25.8325 28.4095 26.0681 29.2892 26.6734 30.0383C27.4374 30.9828 28.9257 30.9273 29.6628 30.4186C30.0244 30.1689 30.26 29.8175 30.323 29.3782C30.3696 29.0522 30.3183 29.0025 29.9894 29.0013C29.4937 28.9979 28.998 29.0013 28.5023 29.0002C28.1069 28.9979 28.0556 28.9505 28.0544 28.5736C28.0521 27.495 28.0521 26.4153 28.0544 25.3367C28.0544 24.9888 28.101 24.9437 28.4568 24.9194C28.5443 24.9136 28.6317 24.9113 28.7192 24.9113C31.3435 24.9113 33.9678 24.9101 36.5921 24.9113C37.32 24.9113 37.32 24.9148 37.32 25.62C37.32 28.4326 37.32 31.2464 37.32 34.059C37.32 34.5445 37.3025 34.5619 36.8091 34.5619C35.5354 34.5619 34.2629 34.5538 32.9893 34.5676C32.6942 34.5711 32.5297 34.4682 32.4632 34.1919C32.4294 34.052 32.4014 33.9099 32.3536 33.7746C32.3023 33.6278 32.2335 33.4867 32.1483 33.2867V33.2879Z"
          fill={stroke || "black"} />
        <path
          d="M10.1848 17.5569C8.93912 17.4817 7.95471 17.4759 6.98664 17.3523C5.44704 17.1569 3.96926 16.7546 2.75742 15.7049C1.75668 14.8379 1.08136 13.7639 0.647471 12.5247C0.27307 11.4553 0.0922838 10.3583 0.0211358 9.22998C-0.168981 6.21158 0.936728 3.80357 3.40941 2.02906C5.92408 0.223339 8.73734 -0.293406 11.7745 0.150509C13.4261 0.39212 15.0182 0.802511 16.468 1.64988C17.779 2.41633 18.7937 3.45676 19.3629 4.87636C19.5157 5.25785 19.609 5.66362 19.7163 6.06245C19.7746 6.27979 19.7023 6.44279 19.4527 6.48787C18.7365 6.61619 18.0204 6.74798 17.3031 6.87283C16.2627 7.05433 15.2235 7.23814 14.1807 7.4046C13.7224 7.47744 13.2582 7.50749 12.7963 7.56067C12.5478 7.58957 12.4522 7.50518 12.4009 7.2416C12.2714 6.57342 12.0556 5.92373 11.4176 5.54802C10.6093 5.07289 9.11991 5.0752 8.41543 6.29135C8.08535 6.86127 7.85091 7.4705 7.81009 8.13175C7.75177 9.07854 7.69578 10.0265 7.68295 10.9744C7.67246 11.6877 7.82175 12.3767 8.14017 13.031C8.56939 13.9131 9.52463 14.2344 10.3796 14.1003C10.9628 14.0079 11.5354 13.8576 11.9028 13.3593C12.121 13.0645 12.2772 12.7189 12.4254 12.3813C12.5268 12.1501 12.6143 11.9732 12.9106 11.9282C14.3312 11.7143 15.7495 11.4866 17.1655 11.2438C17.8606 11.1247 18.5499 10.971 19.2404 10.8218C19.553 10.7548 19.6743 10.8461 19.6148 11.1536C19.1646 13.5177 17.9562 15.3442 15.7495 16.4401C14.8887 16.8679 13.9743 17.1627 13.0132 17.2725C11.9857 17.3904 10.9546 17.4783 10.1848 17.5546V17.5569Z"
          fill={stroke || "black"} />
        <path
          d="M25.7848 10.4159C25.7848 8.49923 25.7848 6.58369 25.7848 4.66699C25.7848 4.18724 25.7615 4.14793 25.2961 4.19649C24.4026 4.29012 23.5115 4.41266 22.6193 4.52017C22.1481 4.57682 21.6757 4.62769 21.2045 4.68202C20.9584 4.70976 20.8499 4.61266 20.8511 4.35717C20.8581 3.21155 20.8581 2.06476 20.8511 0.919137C20.8499 0.662498 20.9607 0.546895 21.2115 0.539959C21.5999 0.528399 21.9883 0.50181 22.3767 0.502966C25.2226 0.508746 28.0697 0.506434 30.9156 0.535335C32.517 0.55152 34.1173 0.618569 35.7187 0.669435C36.3392 0.689087 36.9597 0.723768 37.5802 0.753825C37.9056 0.770009 37.9966 0.84862 37.9977 1.17231C38.0036 2.33759 38.0036 3.50287 37.9954 4.66815C37.9919 5.04039 37.8893 5.08316 37.523 4.99415C36.5281 4.75485 35.5309 4.52827 34.5313 4.30978C34.1546 4.2277 33.7709 4.17799 33.3906 4.12134C33.19 4.09129 33.0757 4.1988 33.043 4.38492C33.0244 4.48781 33.0337 4.59647 33.0337 4.70167C33.0337 8.51542 33.0337 12.328 33.0337 16.1418C33.0337 16.6747 33.0267 16.6805 32.5019 16.6828C30.7628 16.6897 29.0238 16.6955 27.2847 16.7082C26.936 16.7105 26.5872 16.7475 26.2373 16.751C25.8606 16.7533 25.7906 16.6816 25.7895 16.2816C25.7848 15.1742 25.7871 14.0667 25.7871 12.9592C25.7871 12.1118 25.7871 11.2645 25.7871 10.4171L25.7848 10.4159Z"
          fill={stroke || "black"} />
        <path
          d="M1.64575 26.3506C1.64575 23.7784 1.64575 21.2074 1.64575 18.6353C1.64575 18.1763 1.66791 18.152 2.11579 18.1509C4.20475 18.1509 6.29486 18.1509 8.38381 18.1509C8.85502 18.1509 8.88301 18.1798 8.88301 18.6387C8.88301 22.2028 8.88418 25.7668 8.88301 29.3309C8.88301 30.2187 8.95533 30.0915 9.65048 30.0927C12.0602 30.095 14.471 30.0927 16.8807 30.0927C17.3041 30.0927 17.3368 30.1239 17.2691 30.5447C17.0709 31.7793 16.8632 33.0128 16.6638 34.2463C16.6288 34.4636 16.5227 34.5758 16.2952 34.5619C16.2369 34.5584 16.1786 34.5619 16.1203 34.5619C11.456 34.5619 6.79057 34.5619 2.12629 34.5619C1.65975 34.5619 1.64692 34.5492 1.64692 34.0937C1.64692 31.5123 1.64692 28.9309 1.64692 26.3495L1.64575 26.3506Z"
          fill={stroke || "black"} />
      </g>
      <defs>
        <clipPath id="clip0_180_1106">
          <rect width="38" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>


  );
};
