import * as Yup from "yup";

export const EMAIL_VALIDATION_PATTERN = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
export const PASSWORD_VALIDATION_PATTERN = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;


export const LOGIN_PATTERN = Yup.object({
  password: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
  email: Yup.string().matches(EMAIL_VALIDATION_PATTERN, "Invalid email").required("Email is required")
});

export const RESET_PASSWORD_PATTERN = Yup.object({
  password: Yup.string()
    .matches(PASSWORD_VALIDATION_PATTERN, "Password must contain at least 8 characters, one uppercase, one number and one special case character")
    .required("Password is required"),
  confirmPassword: Yup.string().required("Confirm Password is required").test(
    "passwords-match",
    "Passwords must match",
    function(value) {
      return this.parent.password === value;
    }
  )
});
export const REGISTRATION_PATTERN = Yup.object({
  legalName: Yup.string().min(2, "Legal name must be at least 2 characters").required("Legal name is required"),
  artistName: Yup.string().min(2, "Artist name must be at least 2 characters").required("Artist name is required"),
  phone: Yup.string().min(9, "Invalid phone").max(13, "Invalid phone").required("Phone is required"),
  email: Yup.string().matches(EMAIL_VALIDATION_PATTERN, "Invalid email").required("Email is required"),
  password: Yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(PASSWORD_VALIDATION_PATTERN, "Password must contain at least 8 characters, one uppercase, one number and one special case character")
    .required("Password is required")
});


export const ACCOUNT_PATTERN = Yup.object({
  legalName: Yup.string().min(2, "Legal name must be at least 2 characters").required("Legal name is required"),
  artistName: Yup.string().min(2, "Artist name must be at least 2 characters").required("Artist name is required"),
  phone: Yup.string().min(7, "Phone number must be 7 characters").required("Phone is required"),
  email: Yup.string().matches(EMAIL_VALIDATION_PATTERN, "Invalid email").required("Email is required")
});

export const CURRENT_PASSWORD = Yup.object({
  currentPassword: Yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required")
});
export const PASSWORD_CHANGE_PATTERN = Yup.object({
  newPassword: Yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(PASSWORD_VALIDATION_PATTERN, "Password must contain at least 8 characters, one uppercase, one number and one special case character")
    .required("Password is required"),
  repeatPassword: Yup.string().required("Confirm Password is required").test(
    "passwords-match",
    "Please ensure the password you entered matches the first.",
    function(value) {
      return this.parent.newPassword === value;
    }
  )
});

