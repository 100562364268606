export const Auth = {
  email: "",
  password: ""
};
export const RegistrationConstant = {
  legalName: "",
  artistName: "",
  phone: "",
  email: "",
  password: ""
};
export const ForgotPasswordConst = {
  email: "",
  sent: false
};
export const ResetPasswordConst = {
  password: "",
  confirmPassword: ""
};

