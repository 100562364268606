import React, { useState } from "react";
import { FormControl, Typography } from "@mui/material";
import { Auth } from "../../const/Auth";
import styles from "./Auth.module.css";
import { useNavigate } from "react-router-dom";
import routes from "../../routes/const/routes";
import Default from "../../assets/images/LoginImage.png";
import { CustomButton, CustomInput } from "../../components";
import { Logo } from "../../assets/images/file";
import { userLogin } from "../../services/api/authApi";
import { useDispatch } from "react-redux";
import { setRole, setToken, setUser } from "../../store/reducer";
import { useFormik } from "formik";
import { LoginFormValues } from "./types";
import { LOGIN_PATTERN } from "../../const/regex";
import { getProfile } from "../../services/api/lyricsAPI";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const formik = useFormik<LoginFormValues>({
    initialValues: Auth,
    validationSchema: LOGIN_PATTERN,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoading(!loading);

      try {
        const login = await userLogin({
          email: values.email,
          password: values.password
        });
        if (login.jwt) {
          dispatch(setToken(login.jwt));
          dispatch(setRole(login.role));
          const useData = await getProfile();
          dispatch(setUser(useData));
          (login.jwt && useData) && navigate(routes.dashboard);
        } else if (login.response.data.msg) {
          setLoading(false);
          setError(login.response.data.msg);
        }
      } finally {
        setLoading(false);
      }
    }
  });
  return (
    <div className={styles.root}>
      <div>
        <img src={Default} alt={"Default"} loading="lazy" />
      </div>
      <div className={styles.container}>
        <Logo />
        <Typography variant="h5" component="div" mt={26} mb={6} className={styles.title} gutterBottom>
          Log In
        </Typography>
        <FormControl component="form" onSubmit={formik.handleSubmit}>
          <CustomInput
            value={formik.values.email}
            onChange={formik.handleChange("email")}
            label="email"
            id="email"
            placeholder={"example@email.com"}
          />
          <CustomInput
            value={formik.values.password}
            onChange={formik.handleChange("password")}
            label="Password"
            id="Password"
            type={"password"}
            placeholder="••••••••"
          />
          <Typography variant="h5" component="div" mt={2} className={styles.subtitle} gutterBottom
                      onClick={() => navigate(routes.forgotPassword)}>
            Forgot password?
          </Typography>
          <Typography className={styles.error}>{formik.errors.email || formik.errors.password || error}</Typography>
          <CustomButton endIcon={!loading} loading={loading} size={"large"} color={"black"}
                        type="submit">Continue</CustomButton>
        </FormControl>
        <Typography variant="h5" component="div" mt={5} className={styles.subtitle} gutterBottom>
          Don’t have an account? <u onClick={() => navigate(routes.registration)}>Sign up</u>
        </Typography>
      </div>
    </div>
  );
};

export default Login;
