import React from "react";

interface Props {
  off?: boolean;
  handleClick?: () => void;
  fill?: string;
}

export const Volume: React.FC<Props> = ({ off, handleClick, fill }) => {
  return (
    <svg onClick={handleClick} width="20" height="20" viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_18_3589)">
        <path
          d="M8.81613 18.1442C8.651 18.2165 8.45613 18.1823 8.32428 18.0571L3.64304 13.636H0.45456C0.20331 13.636 0 13.4327 0 13.1814V6.81782C0 6.56657 0.20331 6.36326 0.45456 6.36326H3.64304L8.32432 1.94209C8.41045 1.8604 8.52273 1.81779 8.63638 1.81779C8.69719 1.81779 8.75847 1.82976 8.81617 1.85508C8.98309 1.92654 9.09094 2.09077 9.09094 2.27235V17.7269C9.0909 17.9085 8.98305 18.0727 8.81613 18.1442ZM8.18182 3.32704L4.13574 7.14808C4.05141 7.22798 3.93955 7.27238 3.82368 7.27238H0.909077V12.7269H3.82368C3.93955 12.7269 4.05141 12.7713 4.13574 12.8512L8.18182 16.6722V3.32704Z"
          fill={fill || "white"} />
        {off ? <>
            <path
              d="M15.8549 18.095C15.6498 18.2428 15.3662 18.1966 15.2201 17.9938C15.0728 17.7905 15.118 17.5064 15.3213 17.359C17.682 15.6491 19.0909 12.8979 19.0909 9.9997C19.0909 7.10155 17.6819 4.35028 15.3213 2.64041C15.118 2.49305 15.0728 2.20894 15.2201 2.00563C15.3089 1.88312 15.4479 1.81788 15.5886 1.81788C15.6809 1.81788 15.7741 1.84583 15.8549 1.90443C18.4503 3.78475 20 6.81122 20 9.9997C19.9999 13.1882 18.4503 16.2146 15.8549 18.095Z"
              fill={fill || "white"} />
            <path
              d="M13.3811 15.3845C13.1703 15.5177 12.8884 15.4542 12.7544 15.2416C12.6208 15.0294 12.6847 14.7488 12.8973 14.6148C14.4984 13.6072 15.4546 11.8818 15.4546 9.99967C15.4546 8.11755 14.4984 6.39217 12.8973 5.38452C12.6847 5.25046 12.6208 4.96993 12.7544 4.75776C12.8409 4.62016 12.9888 4.54512 13.1397 4.54512C13.2223 4.54512 13.3061 4.56775 13.3812 4.61479C15.2486 5.79021 16.3637 7.80281 16.3637 9.99963C16.3637 12.1964 15.2486 14.209 13.3811 15.3845Z"
              fill={fill || "white"} />
            <path
              d="M11.6229 12.1823C11.4214 12.3328 11.1369 12.2902 10.9868 12.0896C10.8368 11.888 10.8785 11.603 11.0796 11.4535C11.5492 11.1037 11.8182 10.5741 11.8182 9.99969C11.8182 9.4253 11.5492 8.89574 11.0796 8.54593C10.8785 8.39635 10.8368 8.11135 10.9868 7.90983C11.0761 7.78996 11.2128 7.72694 11.3517 7.72694C11.4458 7.72694 11.5413 7.75625 11.623 7.81706C12.3248 8.33951 12.7274 9.1354 12.7274 9.99969C12.7274 10.864 12.3247 11.6598 11.6229 12.1823Z"
              fill={fill || "white"} />
          </> :
          <>
            <path d="M14.8681 12.2097L19.2875 7.79029" stroke={fill || "white"} strokeLinecap="round" />
            <path d="M19.2875 12.2097L14.8681 7.79029" stroke={fill || "white"} strokeLinecap="round" />

          </>}
      </g>
      <defs>
        <clipPath id="clip0_18_3589">
          <rect width="20" height="20" fill={fill || "white"} transform="matrix(1 0 0 -1 0 20)" />
        </clipPath>
      </defs>
    </svg>
  );
};




