import React from "react";

interface Props {
  handleClick?: () => void;
}

export const ActionArrow: React.FC<Props> = ({ handleClick }) => {

  return (
    <svg onClick={handleClick} width="24" height="24" viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_1053_1506" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect y="24" width="24" height="24" transform="rotate(-90 0 24)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1053_1506)">
        <path d="M14.7 12L9.40005 17.3L8.67505 16.6L13.275 12L8.67505 7.40005L9.40005 6.70005L14.7 12Z"
              fill="#0F0F0F" />
      </g>
    </svg>
  );
};




